import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _45dbb5b6 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _beeaa956 = () => interopDefault(import('../pages/account/my.vue' /* webpackChunkName: "pages/account/my" */))
const _41488e16 = () => interopDefault(import('../pages/account/my/accountsettings.vue' /* webpackChunkName: "pages/account/my/accountsettings" */))
const _5e4afc30 = () => interopDefault(import('../pages/account/my/addresses.vue' /* webpackChunkName: "pages/account/my/addresses" */))
const _4bb7262e = () => interopDefault(import('../pages/account/my/labnotebook.vue' /* webpackChunkName: "pages/account/my/labnotebook" */))
const _6ef154cd = () => interopDefault(import('../pages/account/my/myquotes.vue' /* webpackChunkName: "pages/account/my/myquotes" */))
const _0fa2d033 = () => interopDefault(import('../pages/account/my/orderhistory/index.vue' /* webpackChunkName: "pages/account/my/orderhistory/index" */))
const _c74579f2 = () => interopDefault(import('../pages/account/my/orderhistory/_ordernumber.vue' /* webpackChunkName: "pages/account/my/orderhistory/_ordernumber" */))
const _a6b7d648 = () => interopDefault(import('../pages/account/sign-in.vue' /* webpackChunkName: "pages/account/sign-in" */))
const _a3eca756 = () => interopDefault(import('../pages/account/sign-up/index.vue' /* webpackChunkName: "pages/account/sign-up/index" */))
const _286969e4 = () => interopDefault(import('../pages/account/password/change/index.vue' /* webpackChunkName: "pages/account/password/change/index" */))
const _429dad07 = () => interopDefault(import('../pages/account/password/reset/index.vue' /* webpackChunkName: "pages/account/password/reset/index" */))
const _55b7f483 = () => interopDefault(import('../pages/account/sign-up/confirm.vue' /* webpackChunkName: "pages/account/sign-up/confirm" */))
const _a89debf4 = () => interopDefault(import('../pages/account/sign-up/verify.vue' /* webpackChunkName: "pages/account/sign-up/verify" */))
const _0770e0c8 = () => interopDefault(import('../pages/account/sign-up/verify-request.vue' /* webpackChunkName: "pages/account/sign-up/verify-request" */))
const _298cc8fc = () => interopDefault(import('../pages/account/password/change/confirm.vue' /* webpackChunkName: "pages/account/password/change/confirm" */))
const _154e90b5 = () => interopDefault(import('../pages/account/password/reset/confirm.vue' /* webpackChunkName: "pages/account/password/reset/confirm" */))
const _45541d04 = () => interopDefault(import('../pages/account/password/reset/request.vue' /* webpackChunkName: "pages/account/password/reset/request" */))
const _09c28977 = () => interopDefault(import('../pages/account/password/reset/request-confirm.vue' /* webpackChunkName: "pages/account/password/reset/request-confirm" */))
const _b7b70ade = () => interopDefault(import('../pages/browse.vue' /* webpackChunkName: "pages/browse" */))
const _e346f8e6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _2c1803a7 = () => interopDefault(import('../pages/checkout/confirmation.vue' /* webpackChunkName: "pages/checkout/confirmation" */))
const _a6fe2250 = () => interopDefault(import('../pages/checkout/paymentinformation.vue' /* webpackChunkName: "pages/checkout/paymentinformation" */))
const _0df730ca = () => interopDefault(import('../pages/checkout/review.vue' /* webpackChunkName: "pages/checkout/review" */))
const _22b501bc = () => interopDefault(import('../pages/checkout/shippinginformation.vue' /* webpackChunkName: "pages/checkout/shippinginformation" */))
const _1b667fa4 = () => interopDefault(import('../pages/checkout/summary.vue' /* webpackChunkName: "pages/checkout/summary" */))
const _3eb6d705 = () => interopDefault(import('../pages/content/index.vue' /* webpackChunkName: "pages/content/index" */))
const _aeea25c6 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _10434c5e = () => interopDefault(import('../pages/not-found.vue' /* webpackChunkName: "pages/not-found" */))
const _34fee453 = () => interopDefault(import('../pages/pathways/index.vue' /* webpackChunkName: "pages/pathways/index" */))
const _985c3a24 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _21905a76 = () => interopDefault(import('../pages/quote.vue' /* webpackChunkName: "pages/quote" */))
const _20d92f6f = () => interopDefault(import('../pages/quote/confirmation.vue' /* webpackChunkName: "pages/quote/confirmation" */))
const _03625e73 = () => interopDefault(import('../pages/quote/customer-info.vue' /* webpackChunkName: "pages/quote/customer-info" */))
const _46d6d292 = () => interopDefault(import('../pages/quote/review.vue' /* webpackChunkName: "pages/quote/review" */))
const _0309d248 = () => interopDefault(import('../pages/quote/summary.vue' /* webpackChunkName: "pages/quote/summary" */))
const _36c844ce = () => interopDefault(import('../pages/cart/quick-add.vue' /* webpackChunkName: "pages/cart/quick-add" */))
const _07c38b0a = () => interopDefault(import('../pages/ct/punchout/index.vue' /* webpackChunkName: "pages/ct/punchout/index" */))
const _59a13a8e = () => interopDefault(import('../pages/examples/empty.vue' /* webpackChunkName: "pages/examples/empty" */))
const _79768ca3 = () => interopDefault(import('../pages/examples/featureflags/index.vue' /* webpackChunkName: "pages/examples/featureflags/index" */))
const _179a08af = () => interopDefault(import('../pages/examples/form2.vue' /* webpackChunkName: "pages/examples/form2" */))
const _6c428e41 = () => interopDefault(import('../pages/examples/homepage-components-hero.vue' /* webpackChunkName: "pages/examples/homepage-components-hero" */))
const _802d9b0c = () => interopDefault(import('../pages/examples/html.vue' /* webpackChunkName: "pages/examples/html" */))
const _5c6f59db = () => interopDefault(import('../pages/examples/icons.vue' /* webpackChunkName: "pages/examples/icons" */))
const _42c55406 = () => interopDefault(import('../pages/examples/info.vue' /* webpackChunkName: "pages/examples/info" */))
const _5380d8d6 = () => interopDefault(import('../pages/examples/init-testing-id.vue' /* webpackChunkName: "pages/examples/init-testing-id" */))
const _490d8e44 = () => interopDefault(import('../pages/examples/knowledge_base/index.vue' /* webpackChunkName: "pages/examples/knowledge_base/index" */))
const _5916c57a = () => interopDefault(import('../pages/examples/page-with-components-accordions.vue' /* webpackChunkName: "pages/examples/page-with-components-accordions" */))
const _3e3941ef = () => interopDefault(import('../pages/examples/page-with-components-carousel.vue' /* webpackChunkName: "pages/examples/page-with-components-carousel" */))
const _6f3731ee = () => interopDefault(import('../pages/examples/page-with-components-hero.vue' /* webpackChunkName: "pages/examples/page-with-components-hero" */))
const _6d691c24 = () => interopDefault(import('../pages/examples/page-with-components-menu.vue' /* webpackChunkName: "pages/examples/page-with-components-menu" */))
const _ff5d9568 = () => interopDefault(import('../pages/examples/page-with-components-reference.vue' /* webpackChunkName: "pages/examples/page-with-components-reference" */))
const _e7cfadf4 = () => interopDefault(import('../pages/examples/page-with-components-tiles.vue' /* webpackChunkName: "pages/examples/page-with-components-tiles" */))
const _5d83b823 = () => interopDefault(import('../pages/examples/page-with-html.vue' /* webpackChunkName: "pages/examples/page-with-html" */))
const _b35cff6a = () => interopDefault(import('../pages/examples/page-with-zoom.vue' /* webpackChunkName: "pages/examples/page-with-zoom" */))
const _002319db = () => interopDefault(import('../pages/examples/pathways/index.vue' /* webpackChunkName: "pages/examples/pathways/index" */))
const _06e610be = () => interopDefault(import('../pages/examples/productInfo.vue' /* webpackChunkName: "pages/examples/productInfo" */))
const _89d0c082 = () => interopDefault(import('../pages/examples/rich-text.vue' /* webpackChunkName: "pages/examples/rich-text" */))
const _3ee531ee = () => interopDefault(import('../pages/examples/signalstar.vue' /* webpackChunkName: "pages/examples/signalstar" */))
const _1352dc0a = () => interopDefault(import('../pages/examples/special-chars.vue' /* webpackChunkName: "pages/examples/special-chars" */))
const _59c97aa9 = () => interopDefault(import('../pages/examples/static-content.vue' /* webpackChunkName: "pages/examples/static-content" */))
const _a276ae12 = () => interopDefault(import('../pages/examples/storefront.vue' /* webpackChunkName: "pages/examples/storefront" */))
const _292fecae = () => interopDefault(import('../pages/examples/translation-poc.vue' /* webpackChunkName: "pages/examples/translation-poc" */))
const _daf343f0 = () => interopDefault(import('../pages/examples/wistia-video.vue' /* webpackChunkName: "pages/examples/wistia-video" */))
const _67ce3a68 = () => interopDefault(import('../pages/learn-and-support/protein-domains-and-interactions/index.vue' /* webpackChunkName: "pages/learn-and-support/protein-domains-and-interactions/index" */))
const _2a3cb473 = () => interopDefault(import('../pages/learn-and-support/protein-kinases/index.vue' /* webpackChunkName: "pages/learn-and-support/protein-kinases/index" */))
const _ba38d4b0 = () => interopDefault(import('../pages/learn-and-support/protocols/index.vue' /* webpackChunkName: "pages/learn-and-support/protocols/index" */))
const _6544df2c = () => interopDefault(import('../pages/learn-and-support/technical-support/index.vue' /* webpackChunkName: "pages/learn-and-support/technical-support/index" */))
const _a94c5a3e = () => interopDefault(import('../pages/learn-and-support/videos-and-webinars/index.vue' /* webpackChunkName: "pages/learn-and-support/videos-and-webinars/index" */))
const _459cb6fe = () => interopDefault(import('../pages/products/compare.vue' /* webpackChunkName: "pages/products/compare" */))
const _4a60788e = () => interopDefault(import('../pages/products/signalstar.vue' /* webpackChunkName: "pages/products/signalstar" */))
const _70c7f846 = () => interopDefault(import('../pages/products/signalstar/confirmation.vue' /* webpackChunkName: "pages/products/signalstar/confirmation" */))
const _64e4097e = () => interopDefault(import('../pages/products/signalstar/paneldesign.vue' /* webpackChunkName: "pages/products/signalstar/paneldesign" */))
const _189cdefd = () => interopDefault(import('../pages/products/signalstar/quote-request.vue' /* webpackChunkName: "pages/products/signalstar/quote-request" */))
const _340270af = () => interopDefault(import('../pages/products/signalstar/selectantibodies.vue' /* webpackChunkName: "pages/products/signalstar/selectantibodies" */))
const _9d1b9a6c = () => interopDefault(import('../pages/products/signalstar/targetspecies.vue' /* webpackChunkName: "pages/products/signalstar/targetspecies" */))
const _67732d1e = () => interopDefault(import('../pages/about-us/our-company/media-room.vue' /* webpackChunkName: "pages/about-us/our-company/media-room" */))
const _55c0e950 = () => interopDefault(import('../pages/ct/punchout/review.vue' /* webpackChunkName: "pages/ct/punchout/review" */))
const _344f7d28 = () => interopDefault(import('../pages/examples/featureflags/pageUnderFeature.vue' /* webpackChunkName: "pages/examples/featureflags/pageUnderFeature" */))
const _c300e90a = () => interopDefault(import('../pages/examples/featureflags/v2.vue' /* webpackChunkName: "pages/examples/featureflags/v2" */))
const _9248bcc8 = () => interopDefault(import('../pages/examples/pathways/adherens-junction-dynamics-pathway.vue' /* webpackChunkName: "pages/examples/pathways/adherens-junction-dynamics-pathway" */))
const _ffc041f2 = () => interopDefault(import('../pages/examples/pathways/angiogenesis-resources.vue' /* webpackChunkName: "pages/examples/pathways/angiogenesis-resources" */))
const _208262bd = () => interopDefault(import('../pages/examples/pathways/by-disease-area.vue' /* webpackChunkName: "pages/examples/pathways/by-disease-area" */))
const _2df8491c = () => interopDefault(import('../pages/examples/pathways/pathways-alz.vue' /* webpackChunkName: "pages/examples/pathways/pathways-alz" */))
const _60820540 = () => interopDefault(import('../pages/examples/pathways/protein-folding.vue' /* webpackChunkName: "pages/examples/pathways/protein-folding" */))
const _bb1ddb5a = () => interopDefault(import('../pages/learn-and-support/technical-support/articles.vue' /* webpackChunkName: "pages/learn-and-support/technical-support/articles" */))
const _19832f9a = () => interopDefault(import('../pages/products/include/protocol.vue' /* webpackChunkName: "pages/products/include/protocol" */))
const _5bd1d24a = () => interopDefault(import('../pages/examples/distributor/_countryCode.vue' /* webpackChunkName: "pages/examples/distributor/_countryCode" */))
const _6c7251ac = () => interopDefault(import('../pages/examples/knowledge_base/_id.vue' /* webpackChunkName: "pages/examples/knowledge_base/_id" */))
const _0ba4da44 = () => interopDefault(import('../pages/learn-and-support/protocols/_page.vue' /* webpackChunkName: "pages/learn-and-support/protocols/_page" */))
const _0b511989 = () => interopDefault(import('../pages/learn-and-support/videos-and-webinars/_id.vue' /* webpackChunkName: "pages/learn-and-support/videos-and-webinars/_id" */))
const _402e5b46 = () => interopDefault(import('../pages/learn-and-support/technical-support/_articlename/_id/index.vue' /* webpackChunkName: "pages/learn-and-support/technical-support/_articlename/_id/index" */))
const _5803d6d9 = () => interopDefault(import('../pages/products/_id/index.vue' /* webpackChunkName: "pages/products/_id/index" */))
const _4e3f4eec = () => interopDefault(import('../pages/products/_id/applications.vue' /* webpackChunkName: "pages/products/_id/applications" */))
const _2ea78608 = () => interopDefault(import('../pages/products/_id/datasheet.vue' /* webpackChunkName: "pages/products/_id/datasheet" */))
const _20687f24 = () => interopDefault(import('../pages/products/_id/protocol/_protocolid.vue' /* webpackChunkName: "pages/products/_id/protocol/_protocolid" */))
const _25caebed = () => interopDefault(import('../pages/atg/_.vue' /* webpackChunkName: "pages/atg/_" */))
const _177aa21b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _45dbb5b6,
    name: "account",
    children: [{
      path: "my",
      component: _beeaa956,
      name: "account-my",
      children: [{
        path: "accountsettings",
        component: _41488e16,
        name: "account-my-accountsettings"
      }, {
        path: "addresses",
        component: _5e4afc30,
        name: "account-my-addresses"
      }, {
        path: "labnotebook",
        component: _4bb7262e,
        name: "account-my-labnotebook"
      }, {
        path: "myquotes",
        component: _6ef154cd,
        name: "account-my-myquotes"
      }, {
        path: "orderhistory",
        component: _0fa2d033,
        name: "account-my-orderhistory"
      }, {
        path: "orderhistory/:ordernumber",
        component: _c74579f2,
        name: "account-my-orderhistory-ordernumber"
      }]
    }, {
      path: "sign-in",
      component: _a6b7d648,
      name: "account-sign-in"
    }, {
      path: "sign-up",
      component: _a3eca756,
      name: "account-sign-up"
    }, {
      path: "password/change",
      component: _286969e4,
      name: "account-password-change"
    }, {
      path: "password/reset",
      component: _429dad07,
      name: "account-password-reset"
    }, {
      path: "sign-up/confirm",
      component: _55b7f483,
      name: "account-sign-up-confirm"
    }, {
      path: "sign-up/verify",
      component: _a89debf4,
      name: "account-sign-up-verify"
    }, {
      path: "sign-up/verify-request",
      component: _0770e0c8,
      name: "account-sign-up-verify-request"
    }, {
      path: "password/change/confirm",
      component: _298cc8fc,
      name: "account-password-change-confirm"
    }, {
      path: "password/reset/confirm",
      component: _154e90b5,
      name: "account-password-reset-confirm"
    }, {
      path: "password/reset/request",
      component: _45541d04,
      name: "account-password-reset-request"
    }, {
      path: "password/reset/request-confirm",
      component: _09c28977,
      name: "account-password-reset-request-confirm"
    }]
  }, {
    path: "/browse",
    component: _b7b70ade,
    name: "browse"
  }, {
    path: "/checkout",
    component: _e346f8e6,
    name: "checkout",
    children: [{
      path: "confirmation",
      component: _2c1803a7,
      name: "checkout-confirmation"
    }, {
      path: "paymentinformation",
      component: _a6fe2250,
      name: "checkout-paymentinformation"
    }, {
      path: "review",
      component: _0df730ca,
      name: "checkout-review"
    }, {
      path: "shippinginformation",
      component: _22b501bc,
      name: "checkout-shippinginformation"
    }, {
      path: "summary",
      component: _1b667fa4,
      name: "checkout-summary"
    }]
  }, {
    path: "/content",
    component: _3eb6d705,
    name: "content"
  }, {
    path: "/news",
    component: _aeea25c6,
    name: "news"
  }, {
    path: "/not-found",
    component: _10434c5e,
    name: "not-found"
  }, {
    path: "/pathways",
    component: _34fee453,
    name: "pathways"
  }, {
    path: "/products",
    component: _985c3a24,
    name: "products"
  }, {
    path: "/quote",
    component: _21905a76,
    name: "quote",
    children: [{
      path: "confirmation",
      component: _20d92f6f,
      name: "quote-confirmation"
    }, {
      path: "customer-info",
      component: _03625e73,
      name: "quote-customer-info"
    }, {
      path: "review",
      component: _46d6d292,
      name: "quote-review"
    }, {
      path: "summary",
      component: _0309d248,
      name: "quote-summary"
    }]
  }, {
    path: "/cart/quick-add",
    component: _36c844ce,
    name: "cart-quick-add"
  }, {
    path: "/ct/punchout",
    component: _07c38b0a,
    name: "ct-punchout"
  }, {
    path: "/examples/empty",
    component: _59a13a8e,
    name: "examples-empty"
  }, {
    path: "/examples/featureflags",
    component: _79768ca3,
    name: "examples-featureflags"
  }, {
    path: "/examples/form2",
    component: _179a08af,
    name: "examples-form2"
  }, {
    path: "/examples/homepage-components-hero",
    component: _6c428e41,
    name: "examples-homepage-components-hero"
  }, {
    path: "/examples/html",
    component: _802d9b0c,
    name: "examples-html"
  }, {
    path: "/examples/icons",
    component: _5c6f59db,
    name: "examples-icons"
  }, {
    path: "/examples/info",
    component: _42c55406,
    name: "examples-info"
  }, {
    path: "/examples/init-testing-id",
    component: _5380d8d6,
    name: "examples-init-testing-id"
  }, {
    path: "/examples/knowledge_base",
    component: _490d8e44,
    name: "examples-knowledge_base"
  }, {
    path: "/examples/page-with-components-accordions",
    component: _5916c57a,
    name: "examples-page-with-components-accordions"
  }, {
    path: "/examples/page-with-components-carousel",
    component: _3e3941ef,
    name: "examples-page-with-components-carousel"
  }, {
    path: "/examples/page-with-components-hero",
    component: _6f3731ee,
    name: "examples-page-with-components-hero"
  }, {
    path: "/examples/page-with-components-menu",
    component: _6d691c24,
    name: "examples-page-with-components-menu"
  }, {
    path: "/examples/page-with-components-reference",
    component: _ff5d9568,
    name: "examples-page-with-components-reference"
  }, {
    path: "/examples/page-with-components-tiles",
    component: _e7cfadf4,
    name: "examples-page-with-components-tiles"
  }, {
    path: "/examples/page-with-html",
    component: _5d83b823,
    name: "examples-page-with-html"
  }, {
    path: "/examples/page-with-zoom",
    component: _b35cff6a,
    name: "examples-page-with-zoom"
  }, {
    path: "/examples/pathways",
    component: _002319db,
    name: "examples-pathways"
  }, {
    path: "/examples/productInfo",
    component: _06e610be,
    name: "examples-productInfo"
  }, {
    path: "/examples/rich-text",
    component: _89d0c082,
    name: "examples-rich-text"
  }, {
    path: "/examples/signalstar",
    component: _3ee531ee,
    name: "examples-signalstar"
  }, {
    path: "/examples/special-chars",
    component: _1352dc0a,
    name: "examples-special-chars"
  }, {
    path: "/examples/static-content",
    component: _59c97aa9,
    name: "examples-static-content"
  }, {
    path: "/examples/storefront",
    component: _a276ae12,
    name: "examples-storefront"
  }, {
    path: "/examples/translation-poc",
    component: _292fecae,
    name: "examples-translation-poc"
  }, {
    path: "/examples/wistia-video",
    component: _daf343f0,
    name: "examples-wistia-video"
  }, {
    path: "/learn-and-support/protein-domains-and-interactions",
    component: _67ce3a68,
    name: "learn-and-support-protein-domains-and-interactions"
  }, {
    path: "/learn-and-support/protein-kinases",
    component: _2a3cb473,
    name: "learn-and-support-protein-kinases"
  }, {
    path: "/learn-and-support/protocols",
    component: _ba38d4b0,
    name: "learn-and-support-protocols"
  }, {
    path: "/learn-and-support/technical-support",
    component: _6544df2c,
    name: "learn-and-support-technical-support"
  }, {
    path: "/learn-and-support/videos-and-webinars",
    component: _a94c5a3e,
    name: "learn-and-support-videos-and-webinars"
  }, {
    path: "/products/compare",
    component: _459cb6fe,
    name: "products-compare"
  }, {
    path: "/products/signalstar",
    component: _4a60788e,
    name: "products-signalstar",
    children: [{
      path: "confirmation",
      component: _70c7f846,
      name: "products-signalstar-confirmation"
    }, {
      path: "paneldesign",
      component: _64e4097e,
      name: "products-signalstar-paneldesign"
    }, {
      path: "quote-request",
      component: _189cdefd,
      name: "products-signalstar-quote-request"
    }, {
      path: "selectantibodies",
      component: _340270af,
      name: "products-signalstar-selectantibodies"
    }, {
      path: "targetspecies",
      component: _9d1b9a6c,
      name: "products-signalstar-targetspecies"
    }]
  }, {
    path: "/about-us/our-company/media-room",
    component: _67732d1e,
    name: "about-us-our-company-media-room"
  }, {
    path: "/ct/punchout/review",
    component: _55c0e950,
    name: "ct-punchout-review"
  }, {
    path: "/examples/featureflags/pageUnderFeature",
    component: _344f7d28,
    name: "examples-featureflags-pageUnderFeature"
  }, {
    path: "/examples/featureflags/v2",
    component: _c300e90a,
    name: "examples-featureflags-v2"
  }, {
    path: "/examples/pathways/adherens-junction-dynamics-pathway",
    component: _9248bcc8,
    name: "examples-pathways-adherens-junction-dynamics-pathway"
  }, {
    path: "/examples/pathways/angiogenesis-resources",
    component: _ffc041f2,
    name: "examples-pathways-angiogenesis-resources"
  }, {
    path: "/examples/pathways/by-disease-area",
    component: _208262bd,
    name: "examples-pathways-by-disease-area"
  }, {
    path: "/examples/pathways/pathways-alz",
    component: _2df8491c,
    name: "examples-pathways-pathways-alz"
  }, {
    path: "/examples/pathways/protein-folding",
    component: _60820540,
    name: "examples-pathways-protein-folding"
  }, {
    path: "/learn-and-support/technical-support/articles",
    component: _bb1ddb5a,
    name: "learn-and-support-technical-support-articles"
  }, {
    path: "/products/include/protocol",
    component: _19832f9a,
    name: "products-include-protocol"
  }, {
    path: "/examples/distributor/:countryCode?",
    component: _5bd1d24a,
    name: "examples-distributor-countryCode"
  }, {
    path: "/examples/knowledge_base/:id",
    component: _6c7251ac,
    name: "examples-knowledge_base-id"
  }, {
    path: "/learn-and-support/protocols/:page?",
    component: _0ba4da44,
    name: "learn-and-support-protocols-page"
  }, {
    path: "/learn-and-support/videos-and-webinars/:id?",
    component: _0b511989,
    name: "learn-and-support-videos-and-webinars-id"
  }, {
    path: "/learn-and-support/technical-support/:articlename?/:id",
    component: _402e5b46,
    name: "learn-and-support-technical-support-articlename-id"
  }, {
    path: "/products/:id",
    component: _5803d6d9,
    name: "products-id"
  }, {
    path: "/products/:id/applications",
    component: _4e3f4eec,
    name: "products-id-applications"
  }, {
    path: "/products/:id/datasheet",
    component: _2ea78608,
    name: "products-id-datasheet"
  }, {
    path: "/products/:id/protocol/:protocolid?",
    component: _20687f24,
    name: "products-id-protocol-protocolid"
  }, {
    path: "/atg/*",
    component: _25caebed,
    name: "atg-all"
  }, {
    path: "/",
    component: _177aa21b,
    name: "index"
  }, {
    path: "/products/*/:id/applications",
    component: _4e3f4eec,
    name: "custom-products-id-applications"
  }, {
    path: "/products/*/:id/datasheet",
    component: _2ea78608,
    name: "custom-products-id-datasheets"
  }, {
    path: "/products/*/*/:id/protocol/:protocolid",
    component: _20687f24,
    name: "custom-products-id-protocol"
  }, {
    path: "/products/*/:id",
    component: _5803d6d9,
    name: "custom-products-id"
  }, {
    path: "/news(.*)",
    component: _aeea25c6,
    name: "custom-news-page"
  }, {
    path: "/pathways(.*)",
    component: _34fee453,
    name: "custom-pathway-page"
  }, {
    path: "/learn-and-support/protein-domains-and-interactions(.*)",
    component: _67ce3a68,
    name: "custom-protein-domain-page"
  }, {
    path: "/learn-and-support/protein-kinases(.*)",
    component: _2a3cb473,
    name: "custom-protein-kinases-page"
  }, {
    path: "/learn-and-support/protocols(.*)",
    component: _0ba4da44,
    name: "custom-protocol-page"
  }, {
    path: "/learn-and-support/videos-and-webinars",
    component: _a94c5a3e,
    name: "custom-content-videos-page"
  }, {
    path: "/learn-and-support/videos-and-webinars(.*)",
    component: _0b511989,
    name: "custom-content-video-page"
  }, {
    path: "/(applications|research|about-us|services|legal|science-resources|developmental-biology|learn-and-support|product-information)(.*)",
    component: _3eb6d705,
    name: "custom-content-page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
