export const PRODUCT_STATUS = {
  PENDING: 'Pending',
  RELEASED: 'Released',
  RELEASED_ON_HOLD: 'Released - On Hold',
  PRE_DISCONTINUED: 'Pre-discontinued',
  DISCONTINUED: 'Discontinued',
  PRE_RELEASED: 'Pre-released',
  OBSOLETE: 'Obsolete',
  COMMERCIALIZATION: 'Commercialization',
} as const

export const APPLICATIONS = {
  WESTERN_BLOTTING: {
    group: 'W',
    name: 'Western Blotting',
    codeForDisplay: 'WB',
    fullNameForDisplay: 'Western Blotting',
  },
  IMMUNOPRECIPITATION: {
    group: 'IP',
    name: 'Immunoprecipitation',
    codeForDisplay: 'IP',
    fullNameForDisplay: 'Immunoprecipitation',
  },
  IMMUNOHISTOCHEMISTRY: {
    group: 'IHC',
    name: 'Immunohistochemistry',
    codeForDisplay: 'IHC',
    fullNameForDisplay: 'Immunohistochemistry',
  },
  IMMUNOFLUORESCENCE: {
    group: 'IF',
    name: 'Immunofluorescence',
    codeForDisplay: 'IF',
    fullNameForDisplay: 'Immunofluorescence',
  },
  FLOW_CYTOMETRY: {
    group: 'F',
    name: 'Flow Cytometry',
    codeForDisplay: 'F',
    fullNameForDisplay: 'Flow Cytometry',
  },
  CHROMATIN_IMMUNOPRECIPITATION: {
    group: 'ChIP',
    name: 'Chromatin Immunoprecipitation',
    codeForDisplay: 'ChIP',
    fullNameForDisplay: 'Chromatin Immunoprecipitation',
  },
  DOT_BLOT: {
    group: 'Dot Blot',
    name: 'Dot Blot',
    codeForDisplay: 'DB',
    fullNameForDisplay: 'Dot Blot',
  },
  CLIP: {
    group: 'CLIP',
    name: 'CLIP',
    codeForDisplay: 'eCLIP',
    fullNameForDisplay: 'eCLIP',
  },
  CUT_AND_RUN: {
    group: 'CUT&RUN',
    name: 'CUT & RUN',
    codeForDisplay: 'C&R',
    fullNameForDisplay: 'CUT & RUN',
  },
  CUT_AND_TAG: {
    group: 'CUT&Tag',
    name: 'CUT & Tag',
    codeForDisplay: 'C&T',
    fullNameForDisplay: 'CUT & Tag',
  },
  ELISA: {
    group: 'ELISA',
    name: 'ELISA',
    codeForDisplay: 'ELISA',
    fullNameForDisplay: 'ELISA',
  },
  // FIXME: this is just temporary solution until we got the right wording
  INTRASEQ: {
    group: 'SCA',
    name: 'SCA',
    codeForDisplay: 'SCA',
    fullNameForDisplay: 'Single Cell Analysis',
  },
} as const

export const REACTANTS = {
  HUMAN: {
    codeForDisplay: 'H',
    fullNameForDisplay: 'Human',
  },
  MOUSE: {
    codeForDisplay: 'M',
    fullNameForDisplay: 'Mouse',
  },
  RAT: {
    codeForDisplay: 'R',
    fullNameForDisplay: 'Rat',
  },
  HAMSTER: {
    codeForDisplay: 'Hm',
    fullNameForDisplay: 'Hamster',
  },
  MONKEY: {
    codeForDisplay: 'Mk',
    fullNameForDisplay: 'Monkey',
  },
  VIRUS: {
    codeForDisplay: 'Vir',
    fullNameForDisplay: 'Virus',
  },
  MINK: {
    codeForDisplay: 'Mi',
    fullNameForDisplay: 'Mink',
  },
  CHICKEN: {
    codeForDisplay: 'C',
    fullNameForDisplay: 'Chicken',
  },
  D_MELANOGASTER: {
    codeForDisplay: 'Dm',
    fullNameForDisplay: 'D. melanogaster',
  },
  XENOPUS: {
    codeForDisplay: 'X',
    fullNameForDisplay: 'Xenopus',
  },
  ZEBRAFISH: {
    codeForDisplay: 'Z',
    fullNameForDisplay: 'Zebrafish',
  },
  BOVINE: {
    codeForDisplay: 'B',
    fullNameForDisplay: 'Bovine',
  },
  DOG: {
    codeForDisplay: 'Dg',
    fullNameForDisplay: 'Dog',
  },
  PIG: {
    codeForDisplay: 'Pg',
    fullNameForDisplay: 'Pig',
  },
  S_CEREVISIAE: {
    codeForDisplay: 'Sc',
    fullNameForDisplay: 'S. cerevisiae',
  },
  C_ELEGANS: {
    codeForDisplay: 'Ce',
    fullNameForDisplay: 'C. elegans',
  },
  HORSE: {
    codeForDisplay: 'Hr',
    fullNameForDisplay: 'Horse',
  },
  GUINEA_PIG: {
    codeForDisplay: 'GP',
    fullNameForDisplay: 'Guinea Pig',
  },
  RABBIT: {
    codeForDisplay: 'Rab',
    fullNameForDisplay: 'Rabbit',
  },
  GOAT: {
    codeForDisplay: 'G',
    fullNameForDisplay: 'Goat',
  },
  ALL: {
    codeForDisplay: 'All',
    fullNameForDisplay: 'All Species Expected',
  },
} as const

export const SUPPORTING_DATA_ATTRIBUTES = {
  REACTIVITY: 'reactivity',
  SENSITIVITY: 'sensitivity',
  OBSERVED_MOLECULAR_WEIGHT: 'observedMolecularWeight',
  SOURCE: 'source',
  ISOTYPE: 'isotype',
  CONCENTRATION: 'concentration',
  HOMOLOGY: 'homology',
} as const

export const SUPPORTING_DATA_LABELS = {
  REACTIVITY: 'REACTIVITY',
  SENSITIVITY: 'SENSITIVITY',
  OBSERVED_MOLECULAR_WEIGHT: 'MW (kDa)',
  SOURCE: 'SOURCE',
  ISOTYPE: 'Source/Isotype',
  CONCENTRATION: 'Concentration',
  HOMOLOGY: 'Homology',
} as const

export const PRODUCT_INCLUDES_ATTRIBUTES = {
  EMPTY: 'empty',
  PRODUCTINCLUDES: 'productIncludes',
  VOLUME: 'volume',
  SOLUTIONCOLOR: 'solutionColor',
  QUANTITY: 'quantity',
  APPLICATIONS: 'applications',
  REACTIVITY: 'reactivity',
  MOLECULARWEIGHT: 'molecularWeight',
  ISOTYPE: 'isotype',
  CAPCOLOR: 'capColor',
  STORAGETEMP: 'storageTemp',
  HOMOLOGY: 'homology',
  HOST: 'host',
  ANNEALTEMP: 'annealTemp',
  PCRLENGTH: 'pcrLength',
  VOLUME_WITH_COUNT: 'volume_with_count',
  QUANTITY_WITH_COUNT: 'quantity_with_count',
  DILUTION: 'dilution',
} as const

// FIX ME - header names
export const PRODUCT_INCLUDES_LABELS = {
  EMPTY: '',
  PRODUCTINCLUDES: 'Product Includes',
  VOLUME: 'Volume',
  SOLUTIONCOLOR: 'Solution Color',
  QUANTITY: 'Quantity',
  APPLICATIONS: 'Applications',
  REACTIVITY: 'Reactivity',
  MOLECULARWEIGHT: 'MW(kDa)',
  ISOTYPE: 'Isotype',
  CAPCOLOR: 'Cap Color',
  STORAGETEMP: 'Storage Temp',
  HOMOLOGY: 'Homology',
  HOST: 'Host',
  ANNEALTEMP: 'Anneal Temp',
  PCRLENGTH: 'PCR Length',
  VOLUME_WITH_COUNT: 'Volume (with Count)',
  QUANTITY_WITH_COUNT: 'Quantity (with Count)',
  DILUTION: 'Dilution',
} as const

export const PRODUCT_INFORMATION_COMPONENTS = {
  REAGENTS_NOT_INCLUDED: 'ReagentsNotIncluded',
  KIT_USAGE_INFORMATION: 'KitUsageInformation',
  DESCRIPTION: 'Description',
  USAGE_INFORMATION: 'UsageInformation',
  PROTOCOL: 'Protocol',
  QUALITY_CONTROL: 'QualityControl',
  SPECIFICITY_OR_SENSITIVITY: 'SpecificityOrSensitivity',
  SOURCE_OR_PURIFICATION: 'SourceOrPurification',
  BACKGROUND: 'Background',
  UPSTREAM_OR_DOWNSTREAM_PATHWAY_IMAGE: 'UpstreamOrDownstreamPathwayImage',
  DATABASE_LINKS: 'DatabaseLinks',
  FLUORESCENT_PROPERTIES: 'FluorescentProperties',
} as const

export const PUBMED_URL = 'https://pubmed.ncbi.nlm.nih.gov' as const

export const applicationsWithOwnChiclet = [
  'WB',
  'IP',
  'IHC',
  'IF',
  'F',
  'DB',
  'ChIP',
  'C&R',
  'C&T',
  'eCLIP',
  'SCA',
  'ELISA',
]

export const PLACEHOLDER_IMAGE_FILENAME = 'cst-no-image-placeholder-520x350.jpg' as const

export const COMPONENTS_IN_NAVIGATION = {
  SUPPORTING_DATA: {
    ANCHOR: 'supporting-data',
    DISPLAY_NAME: 'Supporting Data',
  },
  RELATED_PRODUCTS: {
    ANCHOR: 'product-list-tabs',
    DISPLAY_NAME: 'Related Products',
  },
  PRODUCT_INCLUDES: {
    ANCHOR: 'product-list-tabs',
    DISPLAY_NAME: 'Product Includes',
  },
  CONJUGATES: {
    ANCHOR: 'product-list-tabs',
    DISPLAY_NAME: 'Conjugates',
  },
  PRODUCT_USAGE: {
    ANCHOR: 'product-usage',
    DISPLAY_NAME: 'Product Usage',
  },
  PROTOCOLS: {
    ANCHOR: 'protocols',
    DISPLAY_NAME: 'Protocols',
  },
  BACKGROUND: {
    ANCHOR: 'background',
    DISPLAY_NAME: 'Background',
  },
  PATHWAYS: {
    ANCHOR: 'pathways',
    DISPLAY_NAME: 'Pathways',
  },
  CITATIONS: {
    ANCHOR: 'citations',
    DISPLAY_NAME: 'Citations',
  },
} as const

export const META_TAGS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  OG_TITLE: 'og:title',
  OG_SITE_NAME: 'og:site_name',
  OG_URL: 'og:url',
  OG_TYPE: 'og:type',
  OG_DESCRIPTION: 'og:description',
  OG_IMAGE: 'og:image',
  OG_IMAGE_WIDTH: 'og:image:width',
  OG_IMAGE_HEIGHT: 'og:image:height',
  TWITTER_TITTLE: 'twitter:title',
  TWITTER_IMAGE: 'twitter:image',
  TWITTER_URL: 'twitter:url',
  TWITTER_CARD: 'twitter:card',
  TWITTER_DESCRIPTION: 'twitter:description',
  TWITTER_SITE: 'twitter:site',
} as const

export const EXCLUDED_KITS = [
  'ChIP Kit',
  'ELISA Kit',
  'FastScan ELISA Kit',
  'Assay Kit',
  'CUT&RUN Kit',
  'Detection System',
  'Buffer Kit',
  'Secondary Antibody',
  'Antibody Sampler Kit',
]

export const PRODUCT_TYPES = {
  ANTIBODY_ARRAY_KIT: 'Antibody Array Kit',
  ANTIBODY_COCKTAIL: 'Antibody Cocktail',
  ANTIBODY_COCKTAIL_KIT: 'Antibody Cocktail Kit',
  ANTIBODY_DUET: 'Antibody Duet',
  ANTIBODY_SAMPLER_KIT: 'Antibody Sampler Kit',
  ASSAY_KIT: 'Assay Kit',
  AQUA_PEPTIDE: 'AQUA Peptide',
  BIOTINYLATED_PEPTIDE: 'Biotinylated Peptide',
  BUFFER: 'Buffer',
  BUFFER_KIT: 'Buffer Kit',
  CELL_EXTRACTS: 'Cell Extracts',
  CELL_EXTRACT_KIT: 'Cell Extract Kit',
  CELLULAR_DYES: 'Cellular Dyes',
  CHEMICAL_MODULATORS: 'Chemical Modulators',
  CHIP_KIT: 'ChIP Kit',
  CUT_AND_RUN_KIT: 'CUT&RUN Kit',
  CUT_AND_TAG_KIT: 'CUT&Tag Kit',
  DETECTION_SYSTEM: 'Detection System',
  DETECTION_SYSTEM_KIT: 'Detection System Kit',
  ELISA_ANTIBODY_PAIR: 'ELISA Antibody Pair',
  ELISA_KIT: 'ELISA Kit',
  ENZYME: 'Enzyme',
  FAST_SCAN_ELISA_KIT: 'FastScan ELISA Kit',
  GROWTH_FACTORS_AND_CYTOKINES: 'Growth Factors and Cytokines',
  IHC_CONTROL_SLIDES: 'IHC Control Slides',
  IHC_KIT: 'IHC Kit',
  MATCHED_ANTIBODY_PAIR: 'Matched Antibody Pair',
  MISCELLANEOUS: 'Miscellaneous',
  MONOCLONAL_ANTIBODY: 'Monoclonal Antibody',
  MW_MARKER: 'MW Marker',
  MW_MARKER_KIT: 'MW Marker Kit',
  OLIGO_ANTIBODY_PAIR: 'Oligo Antibody Pair',
  PHOSPHOPLUS_ANTIBODY_KIT: 'PhosphoPlus Antibody Kit',
  POLYCLONAL_ANTIBODY: 'Polyclonal Antibody',
  PRIMER_SET: 'Primer Set',
  PROTEIN_CONTROL: 'Protein Control',
  PROTEIN_CONTROL_KIT: 'Protein Control Kit',
  PROTEIN_SUBSTRATE: 'Protein Substrate',
  PTM_SCAN: 'PTMScan',
  SECONDARY_ANTIBODY: 'Secondary Antibody',
  SIRNA: 'siRNA',
}

export const ATG_BE_CONF = {
  useCOA: true,
  showNewDatasheets: true,
  mediaUrl: 'media.cellsignal.cn',
  allowedProductTypesForDatasheets: [
    PRODUCT_TYPES.POLYCLONAL_ANTIBODY,
    PRODUCT_TYPES.MONOCLONAL_ANTIBODY,
    PRODUCT_TYPES.SECONDARY_ANTIBODY,
    PRODUCT_TYPES.GROWTH_FACTORS_AND_CYTOKINES,
    PRODUCT_TYPES.CHEMICAL_MODULATORS,
    PRODUCT_TYPES.ANTIBODY_DUET,
    PRODUCT_TYPES.FAST_SCAN_ELISA_KIT,
    PRODUCT_TYPES.ELISA_KIT,
    PRODUCT_TYPES.ELISA_ANTIBODY_PAIR,
    PRODUCT_TYPES.OLIGO_ANTIBODY_PAIR,
    PRODUCT_TYPES.MATCHED_ANTIBODY_PAIR,
    // Uncomment code below once PFW Phase 3 will go live - WEBP-9711
    // PRODUCT_TYPES.PROTEIN_CONTROL,
    // PRODUCT_TYPES.CELLULAR_DYES,
    // PRODUCT_TYPES.CELL_EXTRACTS,
    // PRODUCT_TYPES.CELL_EXTRACT_KIT,
    // PRODUCT_TYPES.MW_MARKER,
    // PRODUCT_TYPES.PTM_SCAN,
    // PRODUCT_TYPES.PROTEIN_CONTROL_KIT,
    // PRODUCT_TYPES.DETECTION_SYSTEM,
    // PRODUCT_TYPES.DETECTION_SYSTEM_KIT,
    // PRODUCT_TYPES.MW_MARKER_KIT,
  ],
  cstCitationWidget: true,
} as const

export const SIGNALSTAR_FLUORESCENCE_REMOVAL_KIT = 'Fluorescence Removal Kit' as const

export const PTM_SCAN_WITH_PRICING_TEMPLATE = 'PTMScan (with Pricing)'

export const INTRASEQ_CONJUGATE_TEMPLATE = 'InTraSeq Conjugate'
export const INTRASEQ_ANTIBODY_COCKTAIL_TEMPLATE = 'InTraSeq Antibody Cocktail'
export const INTRASEQ_ASSAY_KIT_TEMPLATE = 'InTraSeq Assay Kit'

export const INTRASEQ_TEMPLATES = [
  INTRASEQ_CONJUGATE_TEMPLATE,
  INTRASEQ_ANTIBODY_COCKTAIL_TEMPLATE,
  INTRASEQ_ASSAY_KIT_TEMPLATE,
]

export const PRODUCT_CATEGORIES = {
  ACTIVATORS_AND_INHIBITORS: 'Activators & Inhibitors',
  ANTIBODY_ARRAYS: 'Antibody Arrays',
  ANTIBODY_CONJUGATES: 'Antibody Conjugates',
  BUFFERS_AND_DYES: 'Buffers & Dyes',
  CELLSIMPLE: 'CellSimple',
  CELLULAR_ASSAY_KITS: 'Cellular Assay Kits',
  CHIP_KITS_AND_REAGENTS: 'ChIP Kits & Reagents',
  CUT_AND_RUN_KITS_AND_REAGENTS: 'CUT&RUN Kits & Reagents',
  CUT_AND_TAG_KITS_AND_REAGENTS: 'CUT&Tag Kits & Reagents',
  CYTOKINES_AND_GROWTH_FACTORS: 'Cytokines & Growth Factors',
  ELISA_KITS: 'ELISA Kits',
  EXPERIMENTAL_CONTROLS: 'Experimental Controls',
  FLOW_CYTOMETRY_KITS_AND_REAGENTS: 'Flow Cytometry Kits & Reagents',
  INTRASEQ_KITS_AND_REAGENTS: 'InTraSeq™ Kits & Reagents',
  INTRASEQ_KITS_AND_REAGENTS_HTML: 'InTraSeq&#8482; Kits & Reagents',
  MEDIP_KITS_AND_REAGENTS: 'MeDIP Kits & Reagents',
  PRIMARY_ANTIBODIES: 'Primary Antibodies',
  PROTEOMIC_ANALYSIS_PRODUCTS: 'Proteomic Analysis Products',
  RECOMBINANT_PROTEINS: 'Recombinant Proteins',
  SECONDARY_ANTIBODIES: 'Secondary Antibodies',
  SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS: 'SignalStar™ Multiplex IHC Kits & Reagents',
  SIGNALSTAR_MULTIPLEX_IHC_KITS_AND_REAGENTS_HTML: 'SignalStar&#8482; Multiplex IHC Kits & Reagents',
  SIRNA: 'siRNA',
  WB_AND_IP_REAGENTS: 'WB & IP Reagents',
  FLOW_CYTOMETRY_PANELS: 'Flow Cytometry Panels',
} as const

export const ACCORDIANS_GROUP_EXTERNAL_ID = {
  CANCER: 'cancer',
  IMMUNOLOGY_AND_IMMUNO_ONCOLOGY: 'immunology-and-immuno-oncology',
  INFECTIOUS_DISEASES_COVID_19: 'infectious-diseases-covid-19',
  NEUROSCIENCE: 'neuroscience',
} as const
