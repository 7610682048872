export const CstButton = () => import('../../../../libs/storefront-vue/src/components/atoms/Button.vue' /* webpackChunkName: "components/cst-button" */).then(c => wrapFunctional(c.default || c))
export const CstInfoBubble = () => import('../../../../libs/storefront-vue/src/components/atoms/InfoBubble.vue' /* webpackChunkName: "components/cst-info-bubble" */).then(c => wrapFunctional(c.default || c))
export const CstMyComponent = () => import('../../../../libs/storefront-vue/src/components/atoms/MyComponent.vue' /* webpackChunkName: "components/cst-my-component" */).then(c => wrapFunctional(c.default || c))
export const CstTopbar = () => import('../../../../libs/storefront-vue/src/components/atoms/Topbar.vue' /* webpackChunkName: "components/cst-topbar" */).then(c => wrapFunctional(c.default || c))
export const CstFormInputPassword = () => import('../../../../libs/storefront-vue/src/components/molecules/FormInputPassword.vue' /* webpackChunkName: "components/cst-form-input-password" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarNavigation = () => import('../../../../libs/signalstar/src/components/navigation.vue' /* webpackChunkName: "components/cst-signalstar-navigation" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarShowcase = () => import('../../../../libs/signalstar/src/components/showcase.vue' /* webpackChunkName: "components/cst-signalstar-showcase" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarSidebar = () => import('../../../../libs/signalstar/src/components/sidebar.vue' /* webpackChunkName: "components/cst-signalstar-sidebar" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarSidebarDays = () => import('../../../../libs/signalstar/src/components/sidebar_days.vue' /* webpackChunkName: "components/cst-signalstar-sidebar-days" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarSidebarLayer = () => import('../../../../libs/signalstar/src/components/sidebar_layer.vue' /* webpackChunkName: "components/cst-signalstar-sidebar-layer" */).then(c => wrapFunctional(c.default || c))
export const CstSignalstarViewer = () => import('../../../../libs/signalstar/src/components/viewer.vue' /* webpackChunkName: "components/cst-signalstar-viewer" */).then(c => wrapFunctional(c.default || c))
export const GlobalCounter = () => import('../../components/global/Counter.vue' /* webpackChunkName: "components/global-counter" */).then(c => wrapFunctional(c.default || c))
export const GlobalCstBubble = () => import('../../components/global/CstBubble.vue' /* webpackChunkName: "components/global-cst-bubble" */).then(c => wrapFunctional(c.default || c))
export const GlobalDynamicSvg = () => import('../../components/global/DynamicSvg.vue' /* webpackChunkName: "components/global-dynamic-svg" */).then(c => wrapFunctional(c.default || c))
export const GlobalHeader = () => import('../../components/global/Header.vue' /* webpackChunkName: "components/global-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalMarquee = () => import('../../components/global/Marquee.vue' /* webpackChunkName: "components/global-marquee" */).then(c => wrapFunctional(c.default || c))
export const GlobalRenderDebug = () => import('../../components/global/RenderDebug.vue' /* webpackChunkName: "components/global-render-debug" */).then(c => wrapFunctional(c.default || c))
export const GlobalShowFeature = () => import('../../components/global/ShowFeature.vue' /* webpackChunkName: "components/global-show-feature" */).then(c => wrapFunctional(c.default || c))
export const GlobalTemplateValue = () => import('../../components/global/TemplateValue.vue' /* webpackChunkName: "components/global-template-value" */).then(c => wrapFunctional(c.default || c))
export const GlobalTestToast = () => import('../../components/global/test-toast.vue' /* webpackChunkName: "components/global-test-toast" */).then(c => wrapFunctional(c.default || c))
export const FeaturePCPHeaderSection = () => import('../../components/feature/PCP/HeaderSection.vue' /* webpackChunkName: "components/feature-p-c-p-header-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturePCPHighlightDifferences = () => import('../../components/feature/PCP/HighlightDifferences.vue' /* webpackChunkName: "components/feature-p-c-p-highlight-differences" */).then(c => wrapFunctional(c.default || c))
export const FeaturePCPImageSliderSection = () => import('../../components/feature/PCP/ImageSliderSection.vue' /* webpackChunkName: "components/feature-p-c-p-image-slider-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturePCPProductDetailBlock = () => import('../../components/feature/PCP/ProductDetailBlock.vue' /* webpackChunkName: "components/feature-p-c-p-product-detail-block" */).then(c => wrapFunctional(c.default || c))
export const FeaturePCPSmallFigureSlider = () => import('../../components/feature/PCP/SmallFigureSlider.vue' /* webpackChunkName: "components/feature-p-c-p-small-figure-slider" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPAlternativeProductInquiryBlock = () => import('../../components/feature/PDP/AlternativeProductInquiryBlock.vue' /* webpackChunkName: "components/feature-p-d-p-alternative-product-inquiry-block" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPAlternatives = () => import('../../components/feature/PDP/Alternatives.vue' /* webpackChunkName: "components/feature-p-d-p-alternatives" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPAntibodyReferenceLinkPdpWrapper = () => import('../../components/feature/PDP/AntibodyReferenceLinkPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-antibody-reference-link-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPApplicationTab = () => import('../../components/feature/PDP/ApplicationTab.vue' /* webpackChunkName: "components/feature-p-d-p-application-tab" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPApplicationTabPdpWrapper = () => import('../../components/feature/PDP/ApplicationTabPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-application-tab-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPBackground = () => import('../../components/feature/PDP/Background.vue' /* webpackChunkName: "components/feature-p-d-p-background" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationCarousel = () => import('../../components/feature/PDP/CitationCarousel.vue' /* webpackChunkName: "components/feature-p-d-p-citation-carousel" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationCarouselCard = () => import('../../components/feature/PDP/CitationCarouselCard.vue' /* webpackChunkName: "components/feature-p-d-p-citation-carousel-card" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationExtendedView = () => import('../../components/feature/PDP/CitationExtendedView.vue' /* webpackChunkName: "components/feature-p-d-p-citation-extended-view" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationLine = () => import('../../components/feature/PDP/CitationLine.vue' /* webpackChunkName: "components/feature-p-d-p-citation-line" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitations = () => import('../../components/feature/PDP/Citations.vue' /* webpackChunkName: "components/feature-p-d-p-citations" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationsCount = () => import('../../components/feature/PDP/CitationsCount.vue' /* webpackChunkName: "components/feature-p-d-p-citations-count" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationsFilters = () => import('../../components/feature/PDP/CitationsFilters.vue' /* webpackChunkName: "components/feature-p-d-p-citations-filters" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationsPagination = () => import('../../components/feature/PDP/CitationsPagination.vue' /* webpackChunkName: "components/feature-p-d-p-citations-pagination" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationsPdpWrapper = () => import('../../components/feature/PDP/CitationsPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-citations-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPCitationsV3 = () => import('../../components/feature/PDP/CitationsV3.vue' /* webpackChunkName: "components/feature-p-d-p-citations-v3" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPConjugates = () => import('../../components/feature/PDP/Conjugates.vue' /* webpackChunkName: "components/feature-p-d-p-conjugates" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPDatabaseLinks = () => import('../../components/feature/PDP/DatabaseLinks.vue' /* webpackChunkName: "components/feature-p-d-p-database-links" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPDescription = () => import('../../components/feature/PDP/Description.vue' /* webpackChunkName: "components/feature-p-d-p-description" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPFigureBlock = () => import('../../components/feature/PDP/FigureBlock.vue' /* webpackChunkName: "components/feature-p-d-p-figure-block" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPFigureBlockPdpWrapper = () => import('../../components/feature/PDP/FigureBlockPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-figure-block-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPFigureSlider = () => import('../../components/feature/PDP/FigureSlider.vue' /* webpackChunkName: "components/feature-p-d-p-figure-slider" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPFluorescentProperties = () => import('../../components/feature/PDP/FluorescentProperties.vue' /* webpackChunkName: "components/feature-p-d-p-fluorescent-properties" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPInPageNavigation = () => import('../../components/feature/PDP/InPageNavigation.vue' /* webpackChunkName: "components/feature-p-d-p-in-page-navigation" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPInPageNavigationPdpWrapper = () => import('../../components/feature/PDP/InPageNavigationPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-in-page-navigation-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPJapanRegulatoryProductBanner = () => import('../../components/feature/PDP/JapanRegulatoryProductBanner.vue' /* webpackChunkName: "components/feature-p-d-p-japan-regulatory-product-banner" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPKitUsageInformation = () => import('../../components/feature/PDP/KitUsageInformation.vue' /* webpackChunkName: "components/feature-p-d-p-kit-usage-information" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPLegalStatement = () => import('../../components/feature/PDP/LegalStatement.vue' /* webpackChunkName: "components/feature-p-d-p-legal-statement" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPLegalStatementPdpWrapper = () => import('../../components/feature/PDP/LegalStatementPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-legal-statement-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPMobileNavigation = () => import('../../components/feature/PDP/MobileNavigation.vue' /* webpackChunkName: "components/feature-p-d-p-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPNavigationList = () => import('../../components/feature/PDP/NavigationList.vue' /* webpackChunkName: "components/feature-p-d-p-navigation-list" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPPanelBuilder = () => import('../../components/feature/PDP/PanelBuilder.vue' /* webpackChunkName: "components/feature-p-d-p-panel-builder" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPPanelBuilderPdpWrapper = () => import('../../components/feature/PDP/PanelBuilderPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-panel-builder-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPPreviewNotice = () => import('../../components/feature/PDP/PreviewNotice.vue' /* webpackChunkName: "components/feature-p-d-p-preview-notice" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductIncludes = () => import('../../components/feature/PDP/ProductIncludes.vue' /* webpackChunkName: "components/feature-p-d-p-product-includes" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductInformation = () => import('../../components/feature/PDP/ProductInformation.vue' /* webpackChunkName: "components/feature-p-d-p-product-information" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductInformationPdpWrapper = () => import('../../components/feature/PDP/ProductInformationPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-product-information-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductInquiry = () => import('../../components/feature/PDP/ProductInquiry.vue' /* webpackChunkName: "components/feature-p-d-p-product-inquiry" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductInquiryPdpWrapper = () => import('../../components/feature/PDP/ProductInquiryPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-product-inquiry-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductList = () => import('../../components/feature/PDP/ProductList.vue' /* webpackChunkName: "components/feature-p-d-p-product-list" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductListPdpWrapper = () => import('../../components/feature/PDP/ProductListPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-product-list-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductSchema = () => import('../../components/feature/PDP/ProductSchema.vue' /* webpackChunkName: "components/feature-p-d-p-product-schema" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProductStatus = () => import('../../components/feature/PDP/ProductStatus.vue' /* webpackChunkName: "components/feature-p-d-p-product-status" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProtocol = () => import('../../components/feature/PDP/Protocol.vue' /* webpackChunkName: "components/feature-p-d-p-protocol" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPProtocolText = () => import('../../components/feature/PDP/ProtocolText.vue' /* webpackChunkName: "components/feature-p-d-p-protocol-text" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPQualityControl = () => import('../../components/feature/PDP/QualityControl.vue' /* webpackChunkName: "components/feature-p-d-p-quality-control" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPReagentsNotIncluded = () => import('../../components/feature/PDP/ReagentsNotIncluded.vue' /* webpackChunkName: "components/feature-p-d-p-reagents-not-included" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPRecombinantFlag = () => import('../../components/feature/PDP/RecombinantFlag.vue' /* webpackChunkName: "components/feature-p-d-p-recombinant-flag" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPRelatedCarouselCard = () => import('../../components/feature/PDP/RelatedCarouselCard.vue' /* webpackChunkName: "components/feature-p-d-p-related-carousel-card" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPRelatedCarouselModal = () => import('../../components/feature/PDP/RelatedCarouselModal.vue' /* webpackChunkName: "components/feature-p-d-p-related-carousel-modal" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPRelatedProducts = () => import('../../components/feature/PDP/RelatedProducts.vue' /* webpackChunkName: "components/feature-p-d-p-related-products" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPSourceOrPurification = () => import('../../components/feature/PDP/SourceOrPurification.vue' /* webpackChunkName: "components/feature-p-d-p-source-or-purification" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPSpecificityOrSensitivity = () => import('../../components/feature/PDP/SpecificityOrSensitivity.vue' /* webpackChunkName: "components/feature-p-d-p-specificity-or-sensitivity" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPSupportingData = () => import('../../components/feature/PDP/SupportingData.vue' /* webpackChunkName: "components/feature-p-d-p-supporting-data" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPSupportingDataPdpWrapper = () => import('../../components/feature/PDP/SupportingDataPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-supporting-data-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPTechLinks = () => import('../../components/feature/PDP/TechLinks.vue' /* webpackChunkName: "components/feature-p-d-p-tech-links" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPTechLinksPdpWrapper = () => import('../../components/feature/PDP/TechLinksPdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-tech-links-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPTitle = () => import('../../components/feature/PDP/Title.vue' /* webpackChunkName: "components/feature-p-d-p-title" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPToPurchasePdpWrapper = () => import('../../components/feature/PDP/ToPurchasePdpWrapper.vue' /* webpackChunkName: "components/feature-p-d-p-to-purchase-pdp-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPUpstreamOrDownstreamPathwayImage = () => import('../../components/feature/PDP/UpstreamOrDownstreamPathwayImage.vue' /* webpackChunkName: "components/feature-p-d-p-upstream-or-downstream-pathway-image" */).then(c => wrapFunctional(c.default || c))
export const FeaturePDPUsageInformation = () => import('../../components/feature/PDP/UsageInformation.vue' /* webpackChunkName: "components/feature-p-d-p-usage-information" */).then(c => wrapFunctional(c.default || c))
export const FeatureAccountCustomerSupport = () => import('../../components/feature/account/CustomerSupport.vue' /* webpackChunkName: "components/feature-account-customer-support" */).then(c => wrapFunctional(c.default || c))
export const FeatureAccountSignInAndRegistrationLinks = () => import('../../components/feature/account/SignInAndRegistrationLinks.vue' /* webpackChunkName: "components/feature-account-sign-in-and-registration-links" */).then(c => wrapFunctional(c.default || c))
export const FeatureAddressesContactsAddressIcons = () => import('../../components/feature/addresses-contacts/AddressIcons.vue' /* webpackChunkName: "components/feature-addresses-contacts-address-icons" */).then(c => wrapFunctional(c.default || c))
export const FeatureAddressesContactsDeleteAddressModalContent = () => import('../../components/feature/addresses-contacts/DeleteAddressModalContent.vue' /* webpackChunkName: "components/feature-addresses-contacts-delete-address-modal-content" */).then(c => wrapFunctional(c.default || c))
export const FeatureAddressesContactsDeleteContactModalContent = () => import('../../components/feature/addresses-contacts/DeleteContactModalContent.vue' /* webpackChunkName: "components/feature-addresses-contacts-delete-contact-modal-content" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasket = () => import('../../components/feature/basket/Basket.vue' /* webpackChunkName: "components/feature-basket" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebar = () => import('../../components/feature/basket/BasketSidebar.vue' /* webpackChunkName: "components/feature-basket-sidebar" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebarBasketToQuote = () => import('../../components/feature/basket/BasketSidebarBasketToQuote.vue' /* webpackChunkName: "components/feature-basket-sidebar-basket-to-quote" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebarCheckout = () => import('../../components/feature/basket/BasketSidebarCheckout.vue' /* webpackChunkName: "components/feature-basket-sidebar-checkout" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebarHeader = () => import('../../components/feature/basket/BasketSidebarHeader.vue' /* webpackChunkName: "components/feature-basket-sidebar-header" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebarItems = () => import('../../components/feature/basket/BasketSidebarItems.vue' /* webpackChunkName: "components/feature-basket-sidebar-items" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketSidebarTopMessage = () => import('../../components/feature/basket/BasketSidebarTopMessage.vue' /* webpackChunkName: "components/feature-basket-sidebar-top-message" */).then(c => wrapFunctional(c.default || c))
export const FeatureBasketChangeItemPopup = () => import('../../components/feature/basket/ChangeItemPopup.vue' /* webpackChunkName: "components/feature-basket-change-item-popup" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteActiveDiscountCodesBlock = () => import('../../components/feature/checkout-quote/ActiveDiscountCodesBlock.vue' /* webpackChunkName: "components/feature-checkout-quote-active-discount-codes-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteAddressLine = () => import('../../components/feature/checkout-quote/AddressLine.vue' /* webpackChunkName: "components/feature-checkout-quote-address-line" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuotePaymentInfoModal = () => import('../../components/feature/checkout-quote/PaymentInfoModal.vue' /* webpackChunkName: "components/feature-checkout-quote-payment-info-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuotePimAvailability = () => import('../../components/feature/checkout-quote/PimAvailability.vue' /* webpackChunkName: "components/feature-checkout-quote-pim-availability" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteStepper = () => import('../../components/feature/checkout-quote/Stepper.vue' /* webpackChunkName: "components/feature-checkout-quote-stepper" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteButtonsBlockCheckout = () => import('../../components/feature/checkout-quote/buttonsBlockCheckout.vue' /* webpackChunkName: "components/feature-checkout-quote-buttons-block-checkout" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteEmptyBasket = () => import('../../components/feature/checkout-quote/emptyBasket.vue' /* webpackChunkName: "components/feature-checkout-quote-empty-basket" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteFaq = () => import('../../components/feature/checkout-quote/faq.vue' /* webpackChunkName: "components/feature-checkout-quote-faq" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteLegalLinks = () => import('../../components/feature/checkout-quote/legalLinks.vue' /* webpackChunkName: "components/feature-checkout-quote-legal-links" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuotePromoBlock = () => import('../../components/feature/checkout-quote/promoBlock.vue' /* webpackChunkName: "components/feature-checkout-quote-promo-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteSummaryBlock = () => import('../../components/feature/checkout-quote/summaryBlock.vue' /* webpackChunkName: "components/feature-checkout-quote-summary-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureCheckoutQuoteTableBlock = () => import('../../components/feature/checkout-quote/tableBlock.vue' /* webpackChunkName: "components/feature-checkout-quote-table-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountryCode = () => import('../../components/feature/country-selector/CountryCode.vue' /* webpackChunkName: "components/feature-country-code" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountryCodeModal = () => import('../../components/feature/country-selector/CountryCodeModal.vue' /* webpackChunkName: "components/feature-country-code-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountrySelectorInputAutocomplete = () => import('../../components/feature/country-selector/InputAutocomplete.vue' /* webpackChunkName: "components/feature-country-selector-input-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountrySelectorLanguageSelector = () => import('../../components/feature/country-selector/LanguageSelector.vue' /* webpackChunkName: "components/feature-country-selector-language-selector" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountrySelectorRedirectVisitorModal = () => import('../../components/feature/country-selector/RedirectVisitorModal.vue' /* webpackChunkName: "components/feature-country-selector-redirect-visitor-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureCountrySelector = () => import('../../components/feature/country-selector/index.vue' /* webpackChunkName: "components/feature-country-selector" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsFooter = () => import('../../components/feature/datasheets/Footer.vue' /* webpackChunkName: "components/feature-datasheets-footer" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsLiteHeader = () => import('../../components/feature/datasheets/LiteHeader.vue' /* webpackChunkName: "components/feature-datasheets-lite-header" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsNavbar = () => import('../../components/feature/datasheets/Navbar.vue' /* webpackChunkName: "components/feature-datasheets-navbar" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsSectionWithFooter = () => import('../../components/feature/datasheets/SectionWithFooter.vue' /* webpackChunkName: "components/feature-datasheets-section-with-footer" */).then(c => wrapFunctional(c.default || c))
export const FeatureFormCheckoutCreditCard = () => import('../../components/feature/form/CheckoutCreditCard.vue' /* webpackChunkName: "components/feature-form-checkout-credit-card" */).then(c => wrapFunctional(c.default || c))
export const FeatureFormProfileAddress = () => import('../../components/feature/form/ProfileAddress.vue' /* webpackChunkName: "components/feature-form-profile-address" */).then(c => wrapFunctional(c.default || c))
export const FeatureFormProfileContact = () => import('../../components/feature/form/ProfileContact.vue' /* webpackChunkName: "components/feature-form-profile-contact" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementDescription = () => import('../../components/feature/image-enhancement/Description.vue' /* webpackChunkName: "components/feature-image-enhancement-description" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementError = () => import('../../components/feature/image-enhancement/Error.vue' /* webpackChunkName: "components/feature-image-enhancement-error" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementFooter = () => import('../../components/feature/image-enhancement/Footer.vue' /* webpackChunkName: "components/feature-image-enhancement-footer" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementGallery = () => import('../../components/feature/image-enhancement/Gallery.vue' /* webpackChunkName: "components/feature-image-enhancement-gallery" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementHeader = () => import('../../components/feature/image-enhancement/Header.vue' /* webpackChunkName: "components/feature-image-enhancement-header" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementModal = () => import('../../components/feature/image-enhancement/ImageEnhancementModal.vue' /* webpackChunkName: "components/feature-image-enhancement-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementProtocol = () => import('../../components/feature/image-enhancement/Protocol.vue' /* webpackChunkName: "components/feature-image-enhancement-protocol" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementSidebar = () => import('../../components/feature/image-enhancement/Sidebar.vue' /* webpackChunkName: "components/feature-image-enhancement-sidebar" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementSidebarIcon = () => import('../../components/feature/image-enhancement/SidebarIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-sidebar-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementSlider = () => import('../../components/feature/image-enhancement/Slider.vue' /* webpackChunkName: "components/feature-image-enhancement-slider" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementViewer = () => import('../../components/feature/image-enhancement/Viewer.vue' /* webpackChunkName: "components/feature-image-enhancement-viewer" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadConfirmationPage = () => import('../../components/feature/lead/ConfirmationPage.vue' /* webpackChunkName: "components/feature-lead-confirmation-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadCustomerInfoPage = () => import('../../components/feature/lead/CustomerInfoPage.vue' /* webpackChunkName: "components/feature-lead-customer-info-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadDistributorDetails = () => import('../../components/feature/lead/DistributorDetails.vue' /* webpackChunkName: "components/feature-lead-distributor-details" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadDistributorList = () => import('../../components/feature/lead/DistributorList.vue' /* webpackChunkName: "components/feature-lead-distributor-list" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadParentPage = () => import('../../components/feature/lead/LeadParentPage.vue' /* webpackChunkName: "components/feature-lead-parent-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadReviewPage = () => import('../../components/feature/lead/ReviewPage.vue' /* webpackChunkName: "components/feature-lead-review-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureLeadSummaryPage = () => import('../../components/feature/lead/SummaryPage.vue' /* webpackChunkName: "components/feature-lead-summary-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureMyAccountAddressCard = () => import('../../components/feature/my-account/AddressCard.vue' /* webpackChunkName: "components/feature-my-account-address-card" */).then(c => wrapFunctional(c.default || c))
export const FeatureMyAccountDownloadLabNotebook = () => import('../../components/feature/my-account/DownloadLabNotebook.vue' /* webpackChunkName: "components/feature-my-account-download-lab-notebook" */).then(c => wrapFunctional(c.default || c))
export const FeatureMyAccountLabNotebookCard = () => import('../../components/feature/my-account/LabNotebookCard.vue' /* webpackChunkName: "components/feature-my-account-lab-notebook-card" */).then(c => wrapFunctional(c.default || c))
export const FeaturePathwaysPopoverBox = () => import('../../components/feature/pathways/PopoverBox.vue' /* webpackChunkName: "components/feature-pathways-popover-box" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickConjugatesLinks = () => import('../../components/feature/price-brick/ConjugatesLinks.vue' /* webpackChunkName: "components/feature-price-brick-conjugates-links" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickIntraseqLinks = () => import('../../components/feature/price-brick/IntraseqLinks.vue' /* webpackChunkName: "components/feature-price-brick-intraseq-links" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickOldPriceBrickContent = () => import('../../components/feature/price-brick/OldPriceBrickContent.vue' /* webpackChunkName: "components/feature-price-brick-old-price-brick-content" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickContent = () => import('../../components/feature/price-brick/PriceBrickContent.vue' /* webpackChunkName: "components/feature-price-brick-content" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickFooter = () => import('../../components/feature/price-brick/PriceBrickFooter.vue' /* webpackChunkName: "components/feature-price-brick-footer" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickHeader = () => import('../../components/feature/price-brick/PriceBrickHeader.vue' /* webpackChunkName: "components/feature-price-brick-header" */).then(c => wrapFunctional(c.default || c))
export const FeaturePriceBrickPCPAndPLP = () => import('../../components/feature/price-brick/PriceBrickPCPAndPLP.vue' /* webpackChunkName: "components/feature-price-brick-p-c-p-and-p-l-p" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuickAddPriceBrick = () => import('../../components/feature/quick-add/priceBrick.vue' /* webpackChunkName: "components/feature-quick-add-price-brick" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuickAddPriceBrickTable = () => import('../../components/feature/quick-add/priceBrickTable.vue' /* webpackChunkName: "components/feature-quick-add-price-brick-table" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuickAddQuantityInput = () => import('../../components/feature/quick-add/quantityInput.vue' /* webpackChunkName: "components/feature-quick-add-quantity-input" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuickAddPopup = () => import('../../components/feature/quick-add/quickAddPopup.vue' /* webpackChunkName: "components/feature-quick-add-popup" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuoteConfirmationPage = () => import('../../components/feature/quote/ConfirmationPage.vue' /* webpackChunkName: "components/feature-quote-confirmation-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuoteCustomerInfoPage = () => import('../../components/feature/quote/CustomerInfoPage.vue' /* webpackChunkName: "components/feature-quote-customer-info-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuoteParentPage = () => import('../../components/feature/quote/QuoteParentPage.vue' /* webpackChunkName: "components/feature-quote-parent-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuoteReviewPage = () => import('../../components/feature/quote/ReviewPage.vue' /* webpackChunkName: "components/feature-quote-review-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureQuoteSummaryPage = () => import('../../components/feature/quote/SummaryPage.vue' /* webpackChunkName: "components/feature-quote-summary-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarSignalStarIcon = () => import('../../components/feature/signalstar/SignalStarIcon.vue' /* webpackChunkName: "components/feature-signalstar-signal-star-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarColorDropdown = () => import('../../components/feature/signalstar/SignalstarColorDropdown.vue' /* webpackChunkName: "components/feature-signalstar-color-dropdown" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarHowWeModal = () => import('../../components/feature/signalstar/SignalstarHowWeModal.vue' /* webpackChunkName: "components/feature-signalstar-how-we-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarLoader = () => import('../../components/feature/signalstar/SignalstarLoader.vue' /* webpackChunkName: "components/feature-signalstar-loader" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarProductItem = () => import('../../components/feature/signalstar/SignalstarProductItem.vue' /* webpackChunkName: "components/feature-signalstar-product-item" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarSelectionBox = () => import('../../components/feature/signalstar/SignalstarSelectionBox.vue' /* webpackChunkName: "components/feature-signalstar-selection-box" */).then(c => wrapFunctional(c.default || c))
export const FeatureSignalstarTableLine = () => import('../../components/feature/signalstar/SignalstarTableLine.vue' /* webpackChunkName: "components/feature-signalstar-table-line" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentAccordionWrapper = () => import('../../components/feature/static-content/AccordionWrapper.vue' /* webpackChunkName: "components/feature-static-content-accordion-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentAccordionsGroup = () => import('../../components/feature/static-content/AccordionsGroup.vue' /* webpackChunkName: "components/feature-static-content-accordions-group" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentCard = () => import('../../components/feature/static-content/Card.vue' /* webpackChunkName: "components/feature-static-content-card" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentAccordion = () => import('../../components/feature/static-content/ContentAccordion.vue' /* webpackChunkName: "components/feature-static-content-accordion" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContextAccordion = () => import('../../components/feature/static-content/ContextAccordion.vue' /* webpackChunkName: "components/feature-static-content-context-accordion" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentDigitalLibraryTiles = () => import('../../components/feature/static-content/DigitalLibraryTiles.vue' /* webpackChunkName: "components/feature-static-content-digital-library-tiles" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentDirectImg = () => import('../../components/feature/static-content/DirectImg.vue' /* webpackChunkName: "components/feature-static-content-direct-img" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentFAQBlock = () => import('../../components/feature/static-content/FAQBlock.vue' /* webpackChunkName: "components/feature-static-content-f-a-q-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureSlot = () => import('../../components/feature/static-content/FeatureSlot.vue' /* webpackChunkName: "components/feature-slot" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentFilterFacets = () => import('../../components/feature/static-content/FilterFacets.vue' /* webpackChunkName: "components/feature-static-content-filter-facets" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentHero = () => import('../../components/feature/static-content/Hero.vue' /* webpackChunkName: "components/feature-static-content-hero" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentHtmlBlock = () => import('../../components/feature/static-content/HtmlBlock.vue' /* webpackChunkName: "components/feature-static-content-html-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentImageMap = () => import('../../components/feature/static-content/ImageMap.vue' /* webpackChunkName: "components/feature-static-content-image-map" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentMediaRoomAccordions = () => import('../../components/feature/static-content/MediaRoomAccordions.vue' /* webpackChunkName: "components/feature-static-content-media-room-accordions" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentMenu = () => import('../../components/feature/static-content/Menu.vue' /* webpackChunkName: "components/feature-static-content-menu" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentMenuItemGroup = () => import('../../components/feature/static-content/MenuItemGroup.vue' /* webpackChunkName: "components/feature-static-content-menu-item-group" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentNavigationTile = () => import('../../components/feature/static-content/NavigationTile.vue' /* webpackChunkName: "components/feature-static-content-navigation-tile" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentOrderSupport = () => import('../../components/feature/static-content/OrderSupport.vue' /* webpackChunkName: "components/feature-static-content-order-support" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentOrderSupportHSForm = () => import('../../components/feature/static-content/OrderSupportHSForm.vue' /* webpackChunkName: "components/feature-static-content-order-support-h-s-form" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPageTitle = () => import('../../components/feature/static-content/PageTitle.vue' /* webpackChunkName: "components/feature-static-content-page-title" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPathwayModal = () => import('../../components/feature/static-content/PathwayModal.vue' /* webpackChunkName: "components/feature-static-content-pathway-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPathwaysDiagramPage = () => import('../../components/feature/static-content/PathwaysDiagramPage.vue' /* webpackChunkName: "components/feature-static-content-pathways-diagram-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPathwaysLegends = () => import('../../components/feature/static-content/PathwaysLegends.vue' /* webpackChunkName: "components/feature-static-content-pathways-legends" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPathwaysPageTitle = () => import('../../components/feature/static-content/PathwaysPageTitle.vue' /* webpackChunkName: "components/feature-static-content-pathways-page-title" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentProductRibbon = () => import('../../components/feature/static-content/ProductRibbon.vue' /* webpackChunkName: "components/feature-static-content-product-ribbon" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentProteinDomain = () => import('../../components/feature/static-content/ProteinDomain.vue' /* webpackChunkName: "components/feature-static-content-protein-domain" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentReferenceSection = () => import('../../components/feature/static-content/ReferenceSection.vue' /* webpackChunkName: "components/feature-static-content-reference-section" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentRelatedPages = () => import('../../components/feature/static-content/RelatedPages.vue' /* webpackChunkName: "components/feature-static-content-related-pages" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentRichText = () => import('../../components/feature/static-content/RichText.vue' /* webpackChunkName: "components/feature-static-content-rich-text" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentSelectedReviews = () => import('../../components/feature/static-content/SelectedReviews.vue' /* webpackChunkName: "components/feature-static-content-selected-reviews" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentSubcategoryList = () => import('../../components/feature/static-content/SubcategoryList.vue' /* webpackChunkName: "components/feature-static-content-subcategory-list" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentTabs = () => import('../../components/feature/static-content/Tabs.vue' /* webpackChunkName: "components/feature-static-content-tabs" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentTemplateColumnLayout = () => import('../../components/feature/static-content/TemplateColumnLayout.vue' /* webpackChunkName: "components/feature-static-content-template-column-layout" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentTiles = () => import('../../components/feature/static-content/Tiles.vue' /* webpackChunkName: "components/feature-static-content-tiles" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentWistiaCollection = () => import('../../components/feature/static-content/WistiaCollection.vue' /* webpackChunkName: "components/feature-static-content-wistia-collection" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentWistiaVideo = () => import('../../components/feature/static-content/WistiaVideo.vue' /* webpackChunkName: "components/feature-static-content-wistia-video" */).then(c => wrapFunctional(c.default || c))
export const GlobalAccordionsHeading = () => import('../../components/global/accordions/heading.vue' /* webpackChunkName: "components/global-accordions-heading" */).then(c => wrapFunctional(c.default || c))
export const GlobalBackToTopButton = () => import('../../components/global/back-to-top/BackToTopButton.vue' /* webpackChunkName: "components/global-back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const GlobalCmsJsonRteDocRoot = () => import('../../components/global/cms/JsonRteDocRoot.vue' /* webpackChunkName: "components/global-cms-json-rte-doc-root" */).then(c => wrapFunctional(c.default || c))
export const GlobalCmsJsonRteField = () => import('../../components/global/cms/JsonRteField.vue' /* webpackChunkName: "components/global-cms-json-rte-field" */).then(c => wrapFunctional(c.default || c))
export const GlobalCmsVHtml = () => import('../../components/global/cms/VHtml.vue' /* webpackChunkName: "components/global-cms-v-html" */).then(c => wrapFunctional(c.default || c))
export const GlobalDraggableContainer = () => import('../../components/global/draggable/DraggableContainer.vue' /* webpackChunkName: "components/global-draggable-container" */).then(c => wrapFunctional(c.default || c))
export const GlobalDraggableItem = () => import('../../components/global/draggable/DraggableItem.vue' /* webpackChunkName: "components/global-draggable-item" */).then(c => wrapFunctional(c.default || c))
export const GlobalErrors = () => import('../../components/global/errors/Errors.vue' /* webpackChunkName: "components/global-errors" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooter = () => import('../../components/global/footer/Footer.vue' /* webpackChunkName: "components/global-footer" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooterMenuHolder = () => import('../../components/global/footer/MenuHolder.vue' /* webpackChunkName: "components/global-footer-menu-holder" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooterMenuLink = () => import('../../components/global/footer/MenuLink.vue' /* webpackChunkName: "components/global-footer-menu-link" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooterSocial = () => import('../../components/global/footer/Social.vue' /* webpackChunkName: "components/global-footer-social" */).then(c => wrapFunctional(c.default || c))
export const GlobalFormExternalForm = () => import('../../components/global/form/ExternalForm.vue' /* webpackChunkName: "components/global-form-external-form" */).then(c => wrapFunctional(c.default || c))
export const GlobalLoadersTableLoader = () => import('../../components/global/loaders/TableLoader.vue' /* webpackChunkName: "components/global-loaders-table-loader" */).then(c => wrapFunctional(c.default || c))
export const GlobalLoadingButton = () => import('../../components/global/loading/Button.vue' /* webpackChunkName: "components/global-loading-button" */).then(c => wrapFunctional(c.default || c))
export const GlobalMessageBottomMessage = () => import('../../components/global/message/BottomMessage.vue' /* webpackChunkName: "components/global-message-bottom-message" */).then(c => wrapFunctional(c.default || c))
export const GlobalMessageTopMessage = () => import('../../components/global/message/TopMessage.vue' /* webpackChunkName: "components/global-message-top-message" */).then(c => wrapFunctional(c.default || c))
export const GlobalModalsConfirmation = () => import('../../components/global/modals/Confirmation.vue' /* webpackChunkName: "components/global-modals-confirmation" */).then(c => wrapFunctional(c.default || c))
export const GlobalModalsProductAddToCartModal = () => import('../../components/global/modals/ProductAddToCartModal.vue' /* webpackChunkName: "components/global-modals-product-add-to-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavigationBreadcrumbs = () => import('../../components/global/navigation/Breadcrumbs.vue' /* webpackChunkName: "components/global-navigation-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavigation = () => import('../../components/global/navigation/Navigation.vue' /* webpackChunkName: "components/global-navigation" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavigationOverlay = () => import('../../components/global/navigation/Overlay.vue' /* webpackChunkName: "components/global-navigation-overlay" */).then(c => wrapFunctional(c.default || c))
export const GlobalNavigationSidebar = () => import('../../components/global/navigation/Sidebar.vue' /* webpackChunkName: "components/global-navigation-sidebar" */).then(c => wrapFunctional(c.default || c))
export const GlobalOverlay = () => import('../../components/global/overlay/overlay.vue' /* webpackChunkName: "components/global-overlay" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchQuerySuggestions = () => import('../../components/global/search/QuerySuggestions.vue' /* webpackChunkName: "components/global-search-query-suggestions" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearch = () => import('../../components/global/search/Search.vue' /* webpackChunkName: "components/global-search" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSearchinputfield = () => import('../../components/global/search/Searchinputfield.vue' /* webpackChunkName: "components/global-search-searchinputfield" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSuggestionCategory = () => import('../../components/global/search/SuggestionCategory.vue' /* webpackChunkName: "components/global-search-suggestion-category" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSuggestionHeader = () => import('../../components/global/search/SuggestionHeader.vue' /* webpackChunkName: "components/global-search-suggestion-header" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSuggestionHistory = () => import('../../components/global/search/SuggestionHistory.vue' /* webpackChunkName: "components/global-search-suggestion-history" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSuggestionSearchtype = () => import('../../components/global/search/SuggestionSearchtype.vue' /* webpackChunkName: "components/global-search-suggestion-searchtype" */).then(c => wrapFunctional(c.default || c))
export const GlobalSearchSuggestions = () => import('../../components/global/search/Suggestions.vue' /* webpackChunkName: "components/global-search-suggestions" */).then(c => wrapFunctional(c.default || c))
export const GlobalShowmoreShowMore = () => import('../../components/global/showmore/showMore.vue' /* webpackChunkName: "components/global-showmore-show-more" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsBasketSkeleton = () => import('../../components/global/skeletons/BasketSkeleton.vue' /* webpackChunkName: "components/global-skeletons-basket-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsFigureBlockSkeleton = () => import('../../components/global/skeletons/FigureBlockSkeleton.vue' /* webpackChunkName: "components/global-skeletons-figure-block-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsPDPPriceBrickSkeleton = () => import('../../components/global/skeletons/PDPPriceBrickSkeleton.vue' /* webpackChunkName: "components/global-skeletons-p-d-p-price-brick-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsPriceBrickTableSkeleton = () => import('../../components/global/skeletons/PriceBrickTableSkeleton.vue' /* webpackChunkName: "components/global-skeletons-price-brick-table-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsRelatedCarouselCardSkeleton = () => import('../../components/global/skeletons/RelatedCarouselCardSkeleton.vue' /* webpackChunkName: "components/global-skeletons-related-carousel-card-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsRelatedCarouselModalSkeleton = () => import('../../components/global/skeletons/RelatedCarouselModalSkeleton.vue' /* webpackChunkName: "components/global-skeletons-related-carousel-modal-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsSkeleton = () => import('../../components/global/skeletons/Skeleton.vue' /* webpackChunkName: "components/global-skeletons-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSkeletonsTechLinksSkeleton = () => import('../../components/global/skeletons/TechLinksSkeleton.vue' /* webpackChunkName: "components/global-skeletons-tech-links-skeleton" */).then(c => wrapFunctional(c.default || c))
export const GlobalSpinnerCstSpinner = () => import('../../components/global/spinner/cstSpinner.vue' /* webpackChunkName: "components/global-spinner-cst-spinner" */).then(c => wrapFunctional(c.default || c))
export const GlobalTablePagination = () => import('../../components/global/table/TablePagination.vue' /* webpackChunkName: "components/global-table-pagination" */).then(c => wrapFunctional(c.default || c))
export const GlobalTargetersContentTargeter = () => import('../../components/global/targeters/ContentTargeter.vue' /* webpackChunkName: "components/global-targeters-content-targeter" */).then(c => wrapFunctional(c.default || c))
export const GlobalTooltipToolTip = () => import('../../components/global/tooltip/ToolTip.vue' /* webpackChunkName: "components/global-tooltip-tool-tip" */).then(c => wrapFunctional(c.default || c))
export const GlobalTopbar = () => import('../../components/global/topbar/Topbar.vue' /* webpackChunkName: "components/global-topbar" */).then(c => wrapFunctional(c.default || c))
export const FeatureContentHomepageHeroBanner = () => import('../../components/feature/content/homepage/HeroBanner.vue' /* webpackChunkName: "components/feature-content-homepage-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const FeatureContentHomepageRowTiles = () => import('../../components/feature/content/homepage/HomepageRowTiles.vue' /* webpackChunkName: "components/feature-content-homepage-row-tiles" */).then(c => wrapFunctional(c.default || c))
export const FeatureContentHomepageTilesWithLinks = () => import('../../components/feature/content/homepage/HomepageTilesWithLinks.vue' /* webpackChunkName: "components/feature-content-homepage-tiles-with-links" */).then(c => wrapFunctional(c.default || c))
export const FeatureContentHomepageVideo = () => import('../../components/feature/content/homepage/HomepageVideo.vue' /* webpackChunkName: "components/feature-content-homepage-video" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsImageDatasheet = () => import('../../components/feature/datasheets/image/ImageDatasheet.vue' /* webpackChunkName: "components/feature-datasheets-image-datasheet" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsImageDatasheetPage = () => import('../../components/feature/datasheets/image/ImageDatasheetPage.vue' /* webpackChunkName: "components/feature-datasheets-image-datasheet-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainAdditionalInformation = () => import('../../components/feature/datasheets/main/AdditionalInformation.vue' /* webpackChunkName: "components/feature-datasheets-main-additional-information" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainApplicationsKey = () => import('../../components/feature/datasheets/main/ApplicationsKey.vue' /* webpackChunkName: "components/feature-datasheets-main-applications-key" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainBackground = () => import('../../components/feature/datasheets/main/Background.vue' /* webpackChunkName: "components/feature-datasheets-main-background" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainBackgroundReferences = () => import('../../components/feature/datasheets/main/BackgroundReferences.vue' /* webpackChunkName: "components/feature-datasheets-main-background-references" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainBioactivity = () => import('../../components/feature/datasheets/main/Bioactivity.vue' /* webpackChunkName: "components/feature-datasheets-main-bioactivity" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainCasNumber = () => import('../../components/feature/datasheets/main/CasNumber.vue' /* webpackChunkName: "components/feature-datasheets-main-cas-number" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainCompanionProducts = () => import('../../components/feature/datasheets/main/CompanionProducts.vue' /* webpackChunkName: "components/feature-datasheets-main-companion-products" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainCrossReactivityKey = () => import('../../components/feature/datasheets/main/CrossReactivityKey.vue' /* webpackChunkName: "components/feature-datasheets-main-cross-reactivity-key" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainDatasheet = () => import('../../components/feature/datasheets/main/Datasheet.vue' /* webpackChunkName: "components/feature-datasheets-main-datasheet" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainDatasheetTable = () => import('../../components/feature/datasheets/main/DatasheetTable.vue' /* webpackChunkName: "components/feature-datasheets-main-datasheet-table" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainDescription = () => import('../../components/feature/datasheets/main/Description.vue' /* webpackChunkName: "components/feature-datasheets-main-description" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainDirectionsForUse = () => import('../../components/feature/datasheets/main/DirectionsForUse.vue' /* webpackChunkName: "components/feature-datasheets-main-directions-for-use" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainEndotoxin = () => import('../../components/feature/datasheets/main/Endotoxin.vue' /* webpackChunkName: "components/feature-datasheets-main-endotoxin" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainFluorescentProperties = () => import('../../components/feature/datasheets/main/FluorescentProperties.vue' /* webpackChunkName: "components/feature-datasheets-main-fluorescent-properties" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainFormulation = () => import('../../components/feature/datasheets/main/Formulation.vue' /* webpackChunkName: "components/feature-datasheets-main-formulation" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainHeader = () => import('../../components/feature/datasheets/main/Header.vue' /* webpackChunkName: "components/feature-datasheets-main-header" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainHeaderProductSize = () => import('../../components/feature/datasheets/main/HeaderProductSize.vue' /* webpackChunkName: "components/feature-datasheets-main-header-product-size" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainHeaderSupportingData = () => import('../../components/feature/datasheets/main/HeaderSupportingData.vue' /* webpackChunkName: "components/feature-datasheets-main-header-supporting-data" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainHomologies = () => import('../../components/feature/datasheets/main/Homologies.vue' /* webpackChunkName: "components/feature-datasheets-main-homologies" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainLimitedUses = () => import('../../components/feature/datasheets/main/LimitedUses.vue' /* webpackChunkName: "components/feature-datasheets-main-limited-uses" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainMolecularFormula = () => import('../../components/feature/datasheets/main/MolecularFormula.vue' /* webpackChunkName: "components/feature-datasheets-main-molecular-formula" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainMolecularWeight = () => import('../../components/feature/datasheets/main/MolecularWeight.vue' /* webpackChunkName: "components/feature-datasheets-main-molecular-weight" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainProductIncludes = () => import('../../components/feature/datasheets/main/ProductIncludes.vue' /* webpackChunkName: "components/feature-datasheets-main-product-includes" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainProductInformation = () => import('../../components/feature/datasheets/main/ProductInformation.vue' /* webpackChunkName: "components/feature-datasheets-main-product-information" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainProductInformationSupportingData = () => import('../../components/feature/datasheets/main/ProductInformationSupportingData.vue' /* webpackChunkName: "components/feature-datasheets-main-product-information-supporting-data" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainProductUsageInformation = () => import('../../components/feature/datasheets/main/ProductUsageInformation.vue' /* webpackChunkName: "components/feature-datasheets-main-product-usage-information" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainPurity = () => import('../../components/feature/datasheets/main/Purity.vue' /* webpackChunkName: "components/feature-datasheets-main-purity" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainReagentsNotIncluded = () => import('../../components/feature/datasheets/main/ReagentsNotIncluded.vue' /* webpackChunkName: "components/feature-datasheets-main-reagents-not-included" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainResearchUseOnly = () => import('../../components/feature/datasheets/main/ResearchUseOnly.vue' /* webpackChunkName: "components/feature-datasheets-main-research-use-only" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSolubility = () => import('../../components/feature/datasheets/main/Solubility.vue' /* webpackChunkName: "components/feature-datasheets-main-solubility" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSolutionsReagents = () => import('../../components/feature/datasheets/main/SolutionsReagents.vue' /* webpackChunkName: "components/feature-datasheets-main-solutions-reagents" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSourceOrPurification = () => import('../../components/feature/datasheets/main/SourceOrPurification.vue' /* webpackChunkName: "components/feature-datasheets-main-source-or-purification" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSpeciesReactivity = () => import('../../components/feature/datasheets/main/SpeciesReactivity.vue' /* webpackChunkName: "components/feature-datasheets-main-species-reactivity" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSpecificitySensitivity = () => import('../../components/feature/datasheets/main/SpecificitySensitivity.vue' /* webpackChunkName: "components/feature-datasheets-main-specificity-sensitivity" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainStorage = () => import('../../components/feature/datasheets/main/Storage.vue' /* webpackChunkName: "components/feature-datasheets-main-storage" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainSupportingData = () => import('../../components/feature/datasheets/main/SupportingData.vue' /* webpackChunkName: "components/feature-datasheets-main-supporting-data" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainTrademarksAndPatents = () => import('../../components/feature/datasheets/main/TrademarksAndPatents.vue' /* webpackChunkName: "components/feature-datasheets-main-trademarks-and-patents" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsMainUsageInformation = () => import('../../components/feature/datasheets/main/UsageInformation.vue' /* webpackChunkName: "components/feature-datasheets-main-usage-information" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsProtocol = () => import('../../components/feature/datasheets/protocol/Protocol.vue' /* webpackChunkName: "components/feature-datasheets-protocol" */).then(c => wrapFunctional(c.default || c))
export const FeatureDatasheetsProtocolDatasheet = () => import('../../components/feature/datasheets/protocol/ProtocolDatasheet.vue' /* webpackChunkName: "components/feature-datasheets-protocol-datasheet" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementIconsDescriptionIcon = () => import('../../components/feature/image-enhancement/icons/DescriptionIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-icons-description-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementIconsExpandArrowsIcon = () => import('../../components/feature/image-enhancement/icons/ExpandArrowsIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-icons-expand-arrows-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementIconsGalleryIcon = () => import('../../components/feature/image-enhancement/icons/GalleryIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-icons-gallery-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementIconsProtocolIcon = () => import('../../components/feature/image-enhancement/icons/ProtocolIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-icons-protocol-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureImageEnhancementIconsSliderNavIcon = () => import('../../components/feature/image-enhancement/icons/SliderNavIcon.vue' /* webpackChunkName: "components/feature-image-enhancement-icons-slider-nav-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchComparePanel = () => import('../../components/feature/search/compare/ComparePanel.vue' /* webpackChunkName: "components/feature-search-compare-panel" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetAccordion = () => import('../../components/feature/search/facets/FacetAccordion.vue' /* webpackChunkName: "components/feature-search-facets-facet-accordion" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetBlock = () => import('../../components/feature/search/facets/FacetBlock.vue' /* webpackChunkName: "components/feature-search-facets-facet-block" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetBlockAccordion = () => import('../../components/feature/search/facets/FacetBlockAccordion.vue' /* webpackChunkName: "components/feature-search-facets-facet-block-accordion" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetBlockLoop = () => import('../../components/feature/search/facets/FacetBlockLoop.vue' /* webpackChunkName: "components/feature-search-facets-facet-block-loop" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetBlockLoopInner = () => import('../../components/feature/search/facets/FacetBlockLoopInner.vue' /* webpackChunkName: "components/feature-search-facets-facet-block-loop-inner" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetBlockTitle = () => import('../../components/feature/search/facets/FacetBlockTitle.vue' /* webpackChunkName: "components/feature-search-facets-facet-block-title" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetLegend = () => import('../../components/feature/search/facets/FacetLegend.vue' /* webpackChunkName: "components/feature-search-facets-facet-legend" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsFacetSelected = () => import('../../components/feature/search/facets/FacetSelected.vue' /* webpackChunkName: "components/feature-search-facets-facet-selected" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacets = () => import('../../components/feature/search/facets/Facets.vue' /* webpackChunkName: "components/feature-search-facets" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsApplied = () => import('../../components/feature/search/facets/FacetsApplied.vue' /* webpackChunkName: "components/feature-search-facets-applied" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsAppliedInner = () => import('../../components/feature/search/facets/FacetsAppliedInner.vue' /* webpackChunkName: "components/feature-search-facets-applied-inner" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchFacetsShowButton = () => import('../../components/feature/search/facets/FacetsShowButton.vue' /* webpackChunkName: "components/feature-search-facets-show-button" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchHeader = () => import('../../components/feature/search/header/SearchHeader.vue' /* webpackChunkName: "components/feature-search-header" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchNavNavbar = () => import('../../components/feature/search/nav/Navbar.vue' /* webpackChunkName: "components/feature-search-nav-navbar" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchNavPagination = () => import('../../components/feature/search/nav/Pagination.vue' /* webpackChunkName: "components/feature-search-nav-pagination" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchNavResultsPerPage = () => import('../../components/feature/search/nav/ResultsPerPage.vue' /* webpackChunkName: "components/feature-search-nav-results-per-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchPagination = () => import('../../components/feature/search/nav/SearchPagination.vue' /* webpackChunkName: "components/feature-search-pagination" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsPerPage = () => import('../../components/feature/search/nav/SearchResultsPerPage.vue' /* webpackChunkName: "components/feature-search-results-per-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchNavSorting = () => import('../../components/feature/search/nav/Sorting.vue' /* webpackChunkName: "components/feature-search-nav-sorting" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchNavTabs = () => import('../../components/feature/search/nav/Tabs.vue' /* webpackChunkName: "components/feature-search-nav-tabs" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsNoResults = () => import('../../components/feature/search/results/NoResults.vue' /* webpackChunkName: "components/feature-search-results-no-results" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResults = () => import('../../components/feature/search/results/Results.vue' /* webpackChunkName: "components/feature-search-results" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsList = () => import('../../components/feature/search/results/ResultsList.vue' /* webpackChunkName: "components/feature-search-results-list" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsProducts = () => import('../../components/feature/search/results/ResultsProducts.vue' /* webpackChunkName: "components/feature-search-results-products" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsResources = () => import('../../components/feature/search/results/ResultsResources.vue' /* webpackChunkName: "components/feature-search-results-resources" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentComponentsCmsAsset = () => import('../../components/feature/static-content/components/CmsAsset.vue' /* webpackChunkName: "components/feature-static-content-components-cms-asset" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentImage = () => import('../../components/feature/static-content/components/ContentImage.vue' /* webpackChunkName: "components/feature-static-content-image" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentVideo = () => import('../../components/feature/static-content/components/ContentVideo.vue' /* webpackChunkName: "components/feature-static-content-video" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentComponentsForm = () => import('../../components/feature/static-content/components/Form.vue' /* webpackChunkName: "components/feature-static-content-components-form" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentComponentsHTMLTable = () => import('../../components/feature/static-content/components/HTML-Table.vue' /* webpackChunkName: "components/feature-static-content-components-h-t-m-l-table" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentComponentsZoomImage = () => import('../../components/feature/static-content/components/ZoomImage.vue' /* webpackChunkName: "components/feature-static-content-components-zoom-image" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentComponentsZoomImageModal = () => import('../../components/feature/static-content/components/ZoomImageModal.vue' /* webpackChunkName: "components/feature-static-content-components-zoom-image-modal" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPage = () => import('../../components/feature/static-content/content-page/ContentPage.vue' /* webpackChunkName: "components/feature-static-content-page" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentPageWrapper = () => import('../../components/feature/static-content/content-page/ContentPageWrapper.vue' /* webpackChunkName: "components/feature-static-content-page-wrapper" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentCustomComponentsPTMScanPathwaySelector = () => import('../../components/feature/static-content/custom-components/PTMScanPathwaySelector.vue' /* webpackChunkName: "components/feature-static-content-custom-components-p-t-m-scan-pathway-selector" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentCustomComponentsProductFinder = () => import('../../components/feature/static-content/custom-components/ProductFinder.vue' /* webpackChunkName: "components/feature-static-content-custom-components-product-finder" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentCustomComponentsProductFinderInTable = () => import('../../components/feature/static-content/custom-components/ProductFinderInTable.vue' /* webpackChunkName: "components/feature-static-content-custom-components-product-finder-in-table" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentCustomComponentsProductsServicesSelect = () => import('../../components/feature/static-content/custom-components/ProductsServicesSelect.vue' /* webpackChunkName: "components/feature-static-content-custom-components-products-services-select" */).then(c => wrapFunctional(c.default || c))
export const GlobalTargetersContentComponentsAdditionalInformation = () => import('../../components/global/targeters/content-components/AdditionalInformation.vue' /* webpackChunkName: "components/global-targeters-content-components-additional-information" */).then(c => wrapFunctional(c.default || c))
export const GlobalTargetersContentComponentsGeneric = () => import('../../components/global/targeters/content-components/Generic.vue' /* webpackChunkName: "components/global-targeters-content-components-generic" */).then(c => wrapFunctional(c.default || c))
export const GlobalTargetersContentComponentsLegalStatement = () => import('../../components/global/targeters/content-components/LegalStatement.vue' /* webpackChunkName: "components/global-targeters-content-components-legal-statement" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportHeroSection = () => import('../../components/feature/learn-and-support/technical-support/home/SupportHeroSection.vue' /* webpackChunkName: "components/feature-learn-and-support-hero-section" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportLinkTiles = () => import('../../components/feature/learn-and-support/technical-support/home/SupportLinkTiles.vue' /* webpackChunkName: "components/feature-learn-and-support-link-tiles" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportSearchInputField = () => import('../../components/feature/learn-and-support/technical-support/search/SupportSearchInputField.vue' /* webpackChunkName: "components/feature-learn-and-support-search-input-field" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportTypeahead = () => import('../../components/feature/learn-and-support/technical-support/search/SupportTypeahead.vue' /* webpackChunkName: "components/feature-learn-and-support-typeahead" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportArticleBody = () => import('../../components/feature/learn-and-support/technical-support/support-article/SupportArticleBody.vue' /* webpackChunkName: "components/feature-learn-and-support-article-body" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportArticleSidebar = () => import('../../components/feature/learn-and-support/technical-support/support-article/SupportArticleSidebar.vue' /* webpackChunkName: "components/feature-learn-and-support-article-sidebar" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportArticleVote = () => import('../../components/feature/learn-and-support/technical-support/support-article/SupportArticleVote.vue' /* webpackChunkName: "components/feature-learn-and-support-article-vote" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportTechnicalSupportSupportArticlesEmptySupportArticle = () => import('../../components/feature/learn-and-support/technical-support/support-articles/EmptySupportArticle.vue' /* webpackChunkName: "components/feature-learn-and-support-technical-support-support-articles-empty-support-article" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportArticleRow = () => import('../../components/feature/learn-and-support/technical-support/support-articles/SupportArticleRow.vue' /* webpackChunkName: "components/feature-learn-and-support-article-row" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportContactTiles = () => import('../../components/feature/learn-and-support/technical-support/support-tiles/SupportContactTiles.vue' /* webpackChunkName: "components/feature-learn-and-support-contact-tiles" */).then(c => wrapFunctional(c.default || c))
export const FeatureLearnAndSupportTile = () => import('../../components/feature/learn-and-support/technical-support/support-tiles/SupportTile.vue' /* webpackChunkName: "components/feature-learn-and-support-tile" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsCommonApplications = () => import('../../components/feature/search/results/common/Applications.vue' /* webpackChunkName: "components/feature-search-results-common-applications" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsCommonProductheader = () => import('../../components/feature/search/results/common/Productheader.vue' /* webpackChunkName: "components/feature-search-results-common-productheader" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsCommonReactivity = () => import('../../components/feature/search/results/common/Reactivity.vue' /* webpackChunkName: "components/feature-search-results-common-reactivity" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsSearchtypeProduct = () => import('../../components/feature/search/results/searchtype/Product.vue' /* webpackChunkName: "components/feature-search-results-searchtype-product" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsSearchtypeProductAddToBasketIcon = () => import('../../components/feature/search/results/searchtype/ProductAddToBasketIcon.vue' /* webpackChunkName: "components/feature-search-results-searchtype-product-add-to-basket-icon" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsSearchtypeProductPanel = () => import('../../components/feature/search/results/searchtype/ProductPanel.vue' /* webpackChunkName: "components/feature-search-results-searchtype-product-panel" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsSearchtypeProductPanelImage = () => import('../../components/feature/search/results/searchtype/ProductPanelImage.vue' /* webpackChunkName: "components/feature-search-results-searchtype-product-panel-image" */).then(c => wrapFunctional(c.default || c))
export const FeatureSearchResultsSearchtypeResources = () => import('../../components/feature/search/results/searchtype/Resources.vue' /* webpackChunkName: "components/feature-search-results-searchtype-resources" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageCarouselFigureSlide = () => import('../../components/feature/static-content/content-page/carousel/FigureSlide.vue' /* webpackChunkName: "components/feature-static-content-content-page-carousel-figure-slide" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageCarouselFigureSlideImage = () => import('../../components/feature/static-content/content-page/carousel/FigureSlideImage.vue' /* webpackChunkName: "components/feature-static-content-content-page-carousel-figure-slide-image" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageCarousel = () => import('../../components/feature/static-content/content-page/carousel/index.vue' /* webpackChunkName: "components/feature-static-content-content-page-carousel" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageCustomComponent = () => import('../../components/feature/static-content/content-page/customComponent/index.vue' /* webpackChunkName: "components/feature-static-content-content-page-custom-component" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageMenu = () => import('../../components/feature/static-content/content-page/menu/index.vue' /* webpackChunkName: "components/feature-static-content-content-page-menu" */).then(c => wrapFunctional(c.default || c))
export const FeatureStaticContentContentPageReference = () => import('../../components/feature/static-content/content-page/reference/index.vue' /* webpackChunkName: "components/feature-static-content-content-page-reference" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
